import { decode } from "jsonwebtoken"
import React, { FC, useState } from "react"
import { useTimeoutFn } from "react-use"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  aBearerToken,
  aPhase,
  aTimeouted,
  BearerToken,
} from "../../state/global"
import { aLoginPhaseError } from "../../state/login-step"

import Button from "../ui/button"
import Modal from "../ui/modal"

const getTokenExpiration = (token: BearerToken) => {
  if (!token?.length) {
    return -1
  }

  const expiration = decode(token, {
    complete: true,
    json: true,
  })?.payload?.exp

  return expiration ?? -1
}

const TimeoutModal: FC = () => {
  /**
   * timeout is always 5 minutes
   */
  const sec = 300
  const newToken = useRecoilValue(aBearerToken)
  const [phase, setPhase] = useRecoilState(aPhase)
  const [timeouted, setTimeouted] = useRecoilState(aTimeouted)
  const error = useRecoilValue(aLoginPhaseError)
  const setLoginError = useSetRecoilState(aLoginPhaseError)
  const newTokenExpiration = getTokenExpiration(newToken)
  const [currentTokenExpiration, setCurrentTokenExpiration] = useState(-1)
  const [isReady, cancel, reset] = useTimeoutFn(
    () => setTimeouted(true),
    sec * 1000
  )

  /**
   * timeout has been canceled before token arrived or token has been changed
   */
  if (newToken && newTokenExpiration !== currentTokenExpiration) {
    setCurrentTokenExpiration(newTokenExpiration)
    reset()
  }

  /**
   * timeout was set before token arrived or error occured
   */
  if (
    isReady() !== null &&
    !isReady() &&
    (!newToken || error || phase === "error")
  ) {
    cancel()
  }

  const clickAction = () => {
    setTimeouted(false)
    setPhase("error")
    setLoginError("REDIRECT_URL_EXPIRED")
  }

  return (
    <>
      {timeouted && (
        <Modal title="Neúspěšné přihlášení" onClose={clickAction}>
          <strong>
            Než jsme stačili ověřit vaši totožnost, něco se pokazilo. Nezbývá,
            než začít zase tam, kde jste se chtěli přihlásit.
          </strong>
          <div className="py-1 flex justify-end">
            <Button
              testId="button-timeout-confirm"
              variant="green"
              onClick={clickAction}
            >
              Rozumím
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default TimeoutModal
