import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useEffectOnce } from "react-use"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import {
  aPhase,
  aUsername,
  aServiceProvider,
  sIsServiceProviderBankId,
  sIsServiceProviderNIA,
} from "../state/global"
import { sIdPortInit } from "../state/idport"
import { sHandleLoginError } from "../state/management"
import parseHash, { IdPortHash } from "../utils/parse-hash"

/**
 * Parses hash part of URL that contains data to set up APA for different
 * service providers (such as NIA, BankID, our own internet banking)
 */
const useBootApp = (): void => {
  const history = useHistory()
  const { hash } = useLocation()
  const [parsedHash, setParsedHash] = useState<IdPortHash | null>(null)
  const [serviceProvider, setServiceProvider] = useRecoilState(aServiceProvider)
  const initIdPort = useSetRecoilState(sIdPortInit)
  const [, handleLoginError] = useRecoilState(sHandleLoginError)
  const isServiceProviderNIA = useRecoilValue(sIsServiceProviderNIA)
  const isServiceProviderBankId = useRecoilValue(sIsServiceProviderBankId)
  const phase = useRecoilValue(aPhase)
  const userName = useRecoilValue(aUsername)

  /**
   * Step 0: if hash is empty, display error page
   */
  useEffectOnce(() => {
    if (hash.length <= 0 && phase === "preLogin" && userName === "") {
      handleLoginError({
        loginPhaseError: "TOKEN_INVALID",
        ibUrl: "",
      })
    }
  })

  /**
   * Step 1: parse hash
   */
  useEffect(() => {
    if (hash.length > 0) {
      setParsedHash(parseHash(hash))
    }
  }, [hash])

  /**
   * Step 2: set service provider (NiaApp, uuid of BankId app, IB)
   */
  useEffect(() => {
    if (parsedHash?.app_id) {
      setServiceProvider(parsedHash.app_id)
    }
  }, [parsedHash, setServiceProvider])

  /**
   * Step 3: set additional data when provider is handled by IdPort connector (NIA, BankID)
   */
  useEffect(() => {
    if (!serviceProvider || !parsedHash) {
      return
    }

    if (isServiceProviderNIA || isServiceProviderBankId) {
      const { token, state, tracestate } = parsedHash

      initIdPort({ token, state, tracestate })
    }

    history.push("/")
  }, [
    history,
    initIdPort,
    isServiceProviderBankId,
    isServiceProviderNIA,
    parsedHash,
    serviceProvider,
  ])
}

export default useBootApp
