import axios from "axios"

import { IdPortRedirect } from "./types"
import { BearerToken } from "../state/global"
import { Consents } from "../state/idport"

export type FinishAuthorizationRequest = {
  apaSessionId: string
  bearerToken: NonNullable<BearerToken>
} & FinishAuthorizationRequestBody

export type FinishAuthorizationRequestBody = {
  consents: Consents["mandatoryConsents"] & Consents["requiredConsents"]
}

export type FinishAuthorizationResponse = {
  redirect: IdPortRedirect
}

interface ExecFinishAuthorization<T> {
  (data: T): Promise<FinishAuthorizationResponse>
}

export const execFinishAuthorization: ExecFinishAuthorization<FinishAuthorizationRequest> =
  async data => {
    const { apaSessionId, bearerToken, consents } = data

    // TODO handle 400 errors?
    const response = await axios.post<FinishAuthorizationResponse>(
      `/api/apa/v1/bankid/finishAuthorization?${apaSessionId}`,
      {
        consents,
      },
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      }
    )

    const { redirect } = response.data

    return { redirect }
  }
