import { FC } from "react"
import FormattedSignRow from "./formatted-sign-row"
import lock from "../../../assets/lock.png"
import { getPageSuffix } from "./utils"

type SingleDocumentSignData = {
  documentHash: string
  title: string
  subject: string
  uri: string
  pageCount: number
}

export type FormattedSingleDocumentSignProps = {
  data: Partial<SingleDocumentSignData>
}

const FormattedSingleDocumentSign: FC<FormattedSingleDocumentSignProps> = ({
  data,
}) => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { documentHash, title, pageCount, subject, uri } = data

  return (
    <div className="p-5 pb-2 flex flex-col text-xs mb-3 border border-solid border-black-da rounded-md">
      {uri && (
        <FormattedSignRow label="Název dokumentu" noBoldValue>
          <a
            className="inline-block underline hover:no-underline font-bold"
            href={uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title || "bez názvu"}
          </a>{" "}
          <span className="text-black-4 mr-1">
            {pageCount ? `(${pageCount} stran${getPageSuffix(pageCount)})` : ""}
          </span>{" "}
          <img src={lock} alt="Lock" width={8} className="inline" />
        </FormattedSignRow>
      )}
      {subject && (
        <FormattedSignRow label="Popis dokumentu" noBoldValue>
          {subject}
        </FormattedSignRow>
      )}
      {documentHash && (
        <FormattedSignRow label="Otisk dokumentu" noBoldValue>
          SHA512(<span className="text-black-4">{documentHash}</span>)
        </FormattedSignRow>
      )}
    </div>
  )
}

export default FormattedSingleDocumentSign
