import axios, { AxiosResponse } from "axios"

import { FilledBearerToken } from "../state/global"

type RejectAuthorizationResponse = AxiosResponse<Record<string, never>>

type RejectAuthorizationRequest = {
  rejectionReason: "OTHER" | "CONSENTS_REJECTED"
  bearerToken: FilledBearerToken
}

interface ExecRejectAuthorization {
  (data: RejectAuthorizationRequest): Promise<RejectAuthorizationResponse>
}

const execRejectAuthorization: ExecRejectAuthorization = async data => {
  const { rejectionReason, bearerToken } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
    // TODO
  }

  return axios.post(
    `/api/apa/v1/bankid/rejectAuthorization`,
    {
      reason: rejectionReason,
    },
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )
}

export default execRejectAuthorization
