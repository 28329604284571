import axios from "axios"

import { FilledBearerToken, ServiceProvider } from "../state/global"
import { IdPortHash } from "../utils/parse-hash"
import { DocumentsToSign, ObjectToSign } from "../state/idport"

/**
 * PRELOGIN
 */
type PreLoginResponse = {
  apaInitAuthToken: FilledBearerToken
  appName: string
  providerName: string
  redirectUrl: string
  operationType: "AUTHENTICATION" | "DOCUMENT_SIGN" | ""
  documents: DocumentsToSign
  signObject: ObjectToSign
  signRequestId: string
  envelopeName: string
  envelopeHash: string
}

export type PreLoginRequestBody = {
  appId: NonNullable<ServiceProvider>
  token1: NonNullable<IdPortHash["token"]>
  state: NonNullable<IdPortHash["state"]>
  tracestate: NonNullable<IdPortHash["tracestate"]>
}

export type PreLoginRequest = PreLoginRequestBody & {
  apaSessionId: string
}

interface ExecPreLogin<T> {
  (data: T): Promise<PreLoginResponse>
}

export const execPreLogin: ExecPreLogin<PreLoginRequest> = async data => {
  const { apaSessionId, appId, token1, state, tracestate } = data

  // TODO handle 400 errors?
  const response = await axios.post<PreLoginResponse>(
    `/api/apa/v1/bankid/preLogin?${apaSessionId}`,
    { appId, state, token1, tracestate }
  )

  const {
    apaInitAuthToken,
    appName,
    redirectUrl,
    providerName,
    operationType,
    documents,
    signObject,
    signRequestId,
    envelopeName,
    envelopeHash,
  } = response.data

  return {
    apaInitAuthToken,
    appName,
    redirectUrl,
    providerName,
    operationType,
    documents,
    signObject,
    signRequestId,
    envelopeName,
    envelopeHash,
  }
}
