import React, { FC } from "react"

const SWTOnline: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Potvrďte přihlášení ve svém mobilu
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Na&nbsp;telefon by&nbsp;vám mělo přijít oznámení, že&nbsp;se&nbsp;chcete
      přihlásit. Tak&nbsp;ho jenom potvrďte, zadejte heslo do&nbsp;mobilní
      aplikace a&nbsp;jste tam.
    </p>
  </>
)

export default SWTOnline
