import React, { FC, useEffect, useState } from "react"
import { useEffectOnce, useLocation } from "react-use"
import styles from "./index.module.css"

import Footer from "../../components/footer/footer"
import RedirectFail from "../../components/global-error/redirect-fail"
import Redirecting from "../../components/aside/redirecting"
import Header from "../../components/header/header"
import execProcessAuthorizationResult, {
  bankIDAuthKeys,
  BankIDAuthQueryParams,
} from "../../requests/process-auth-result"

const PublicAuthResult: FC = () => {
  const { search } = useLocation()
  const [query, setQuery] = useState<Record<string, string>>({})

  const parseQueryString = (queryString: string): BankIDAuthQueryParams => {
    if (queryString.length <= 0) {
      return {}
    }

    type BankIDAuthKeyType = keyof BankIDAuthQueryParams

    // TODO: use https://www.npmjs.com/package/query-string (currently problem with import)
    const parts = queryString.substring(1).split("&")
    const pairs = parts.map(part => part.split("="))

    const objArray = pairs
      .filter(([key]) => {
        return bankIDAuthKeys.includes(key as BankIDAuthKeyType)
      })
      .map(([key, val]) => {
        return {
          [key as BankIDAuthKeyType]: decodeURIComponent(val),
        }
      })

    const parsedQuery = Object.assign({}, ...objArray)

    // prioritize error before code
    if (parsedQuery.error && parsedQuery.code) {
      delete parsedQuery.code
    }

    return parsedQuery
  }

  useEffectOnce(() => {
    if (typeof search == "string") {
      const queryObj = parseQueryString(search)
      setQuery(queryObj)
    }
  })

  useEffect(() => {
    if (query.state !== undefined && query.state) {
      execProcessAuthorizationResult(query)
    }
  }, [query])

  return (
    <>
      <section className="mx-auto max-w-5xl px-6">
        <Header />
        <div
          className={`${styles.wrapper} flex flex-col sm:block relative bg-white border border-solid border-black-da rounded-md`}
        >
          <div className="flex flex-col p-4 sm:p-10">
            <Redirecting />
          </div>
          <aside
            className={`${styles.form} sm:absolute sm:top-0 sm:right-0 sm:bottom-0 sm:-mt-4 sm:-mb-5 sm:mr-5 sm:ml-0 -m-px p-5 flex flex-col rounded-md bg-black-fa border border-solid border-black-b0`}
          >
            <RedirectFail />
          </aside>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default PublicAuthResult
