import { FC, useState } from "react"
import FormattedSignRow from "./formatted-sign-row"
import { getPageSuffix } from "./utils"
import lock from "../../../assets/lock.png"

type DocumentData = {
  id: string
  title: string
  subject: string
  uri: string
  pageCount: number
}

type MultiDocumentSignData = {
  documents: Partial<DocumentData>[]
  envelopeHash: string
  envelopeName: string
}

export type FormattedMultiDocumentSignProps = {
  data: Partial<MultiDocumentSignData>
}

const FormattedMultiDocumentSign: FC<FormattedMultiDocumentSignProps> = ({
  data,
}) => {
  const [docsOpen, setDocsOpen] = useState(false)

  if (!data || data.documents?.length === 0) {
    return null
  }

  const { documents, envelopeName, envelopeHash } = data

  return (
    <div className="p-5 pb-2 flex flex-col text-xs mb-3 border border-solid border-black-da rounded-md">
      {envelopeName && (
        <FormattedSignRow label="Název obálky">{envelopeName}</FormattedSignRow>
      )}
      {documents && (
        <FormattedSignRow label="Dokumenty v obálce" noBoldValue>
          <div className="flex flex-no-wrap items-center">
            <div className="inline-flex bg-green-light text-black-4 mr-1 rounded-full p-1 font-bold text-xs w-6 align-middle justify-center h-6">
              {documents.length}
            </div>
            <button
              className="inline-block underline hover:no-underline cursor-pointer text-left"
              onClick={() => {
                setDocsOpen(!docsOpen)
              }}
              onKeyPress={() => {
                setDocsOpen(!docsOpen)
              }}
              role="link"
              tabIndex={0}
            >
              {`${docsOpen ? "Skrýt" : "Zobrazit"} všechny dokumenty`}
            </button>
          </div>
        </FormattedSignRow>
      )}

      {envelopeHash && (
        <FormattedSignRow label="Otisk obálky" noBoldValue>
          SHA512(<span className="text-black-4">{envelopeHash}</span>)
        </FormattedSignRow>
      )}

      {docsOpen &&
        documents?.map(({ title, uri, subject, pageCount, id }) => {
          return (
            <div
              className="mt-1 pt-4 flex flex-col text-xs border-t border-solid border-black-da"
              key={id}
            >
              {uri && (
                <FormattedSignRow label="Název dokumentu" noBoldValue>
                  <a
                    className="inline-block underline hover:no-underline font-bold"
                    href={uri}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title || "bez názvu"}
                  </a>{" "}
                  <span className="text-black-4 mr-1">
                    {pageCount
                      ? `(${pageCount} stran${getPageSuffix(pageCount)})`
                      : ""}
                  </span>{" "}
                  <img src={lock} alt="Lock" width={8} className="inline" />
                </FormattedSignRow>
              )}
              {subject && (
                <FormattedSignRow label="Popis dokumentu" noBoldValue>
                  {subject}
                </FormattedSignRow>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default FormattedMultiDocumentSign
