import React, { FC } from "react"

const NoAuthorizationMethod: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Nemůžeme vás&nbsp;přihlásit
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Pravděpodobně nemáte správně nastavené potřebné bezpečnostní prvky.
      Zavolejte nám prosím&nbsp;na 515&nbsp;202&nbsp;202 a&nbsp;problém obratem
      vyřešíme.
    </p>
    <p className="mt-4 text-sm">
      K&nbsp;hovoru použijte číslo telefonu uvedeného ve&nbsp;vašem bankovnictví
      nebo mějte k&nbsp;dispozici naši&nbsp;aplikaci My&nbsp;Air.
    </p>
  </>
)

export default NoAuthorizationMethod
