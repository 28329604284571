import React, { FC } from "react"

const AuthorizationFail: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">Neúspěšné přihlášení</h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Nepodařilo se&nbsp;nám ověřit vaši&nbsp;totožnost. Vrátíme
      vás&nbsp;zpět&nbsp;k poskytovateli služeb, kde&nbsp;jste se&nbsp;chtěli
      pomocí bankovní identity ověřit.
    </p>
  </>
)

export default AuthorizationFail
