/**
 * This utility is used to encrypt user password client side before sending
 * it to the APA BE.
 *
 * We use the public part of a RSA key provided by BE to encrypt the password.
 *
 * BE then tries to decrypt it using the private key.
 *
 * If it fails, BE tries to validate the password as if we sent a plaintext
 * value. Which is guaranteed to fail since we always encrypt before sending.
 */
import "promiz"
import "webcrypto-shim"
import { arrayBufferToBase64, stringToArrayBuffer } from "./helpers"

interface ImportPublicKey {
  (pem: string): Promise<CryptoKey>
}

/**
 * Imports a string representation of a public RSA key using `window.crypto`,
 * first converting the string key into a binary ArrayBuffer representation
 */
const importPublicKey: ImportPublicKey = async pem => {
  const binaryDer = stringToArrayBuffer(pem, "binary")
  /* eslint-disable-next-line */
  /* @ts-ignore */
  const cryptoObj = window.crypto || window.msCrypto /* IE11 native */
  const result = await cryptoObj.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt"]
  )
  return result
}

interface EncryptDataWithPublicKey {
  (data: string, key: CryptoKey): Promise<ArrayBuffer>
}

/**
 * Encrypts given string data with a key obtained from `window.crypto`
 * importKey using an RSA public key
 */
const encryptDataWithPublicKey: EncryptDataWithPublicKey = async (
  data,
  key
) => {
  /* eslint-disable-next-line */
  /* @ts-ignore */
  const cryptoObj = window.crypto || window.msCrypto /* IE11 native */
  return cryptoObj.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    key,
    stringToArrayBuffer(data)
  )
}

interface PasswordEncrypt {
  (password: string, publicKey: string): Promise<string>
}

/**
 * Encrypts given password using given public RSA key using `window.crypto`
 *
 * @see https://stackoverflow.com/a/62967202
 */
const encryptPassword: PasswordEncrypt = async (password, publicKey) => {
  const encryptionKey = await importPublicKey(publicKey)
  const encryptedPassword = await encryptDataWithPublicKey(
    password,
    encryptionKey
  )

  return arrayBufferToBase64(encryptedPassword)
}

export default encryptPassword
