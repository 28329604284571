import axios from "axios"

type ProcessAuthorizationResultResponse = number

export const bankIDAuthKeys = ["state", "error", "code"] as const

export type BankIDAuthQueryParams = {
  [K in typeof bankIDAuthKeys[number]]?: string
}

interface ExecProcessAuthorizationResult {
  (data: BankIDAuthQueryParams): Promise<ProcessAuthorizationResultResponse>
}

const execProcessAuthorizationResult: ExecProcessAuthorizationResult =
  async data => {
    const response = await axios.post(
      `/api/apa/v1/public/bankid/processBankIdAuthorizationResult`,
      data
    )

    return response.status
  }

export default execProcessAuthorizationResult
