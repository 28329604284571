import React, { FC } from "react"

import { useRecoilValue } from "recoil"
import styles from "./footer.module.css"
import { aPhase } from "../../state/global"
import { aRedirectUrl } from "../../state/login-step"
import RedirectForm from "../redirect-form"

import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg"

const Footer: FC = () => {
  const phase = useRecoilValue(aPhase)
  const redirectUrl = useRecoilValue(aRedirectUrl)

  return (
    <footer className="sm:flex flex-wrap justify-between mt-6 px-5 text-tiny space-y-2 sm:space-y-0">
      <div className="flex">
        <div className="space-y-2 sm:space-y-0">
          <div className={`${styles.phone} text-black-6 pl-5 bg-no-repeat`}>
            <strong>Chcete pomoci?</strong> Volejte 515 202 202
          </div>
          <a
            data-testid="link-assistent-aneta"
            href="https://www.airbank.cz/poradna/"
            className={`${styles.assistant} inline-block pl-5 underline hover:no-underline bg-no-repeat`}
          >
            Nebo se zeptejte naší asistentky Anety
          </a>
        </div>
        {phase === "login" && redirectUrl && (
          <div className="hidden sm:block border-l border-black-6 pl-2 ml-2 ">
            <RedirectForm autoSubmit={false} action="CANCEL">
              <button type="submit" className="underline font-bold">
                <ArrowLeft className="inline-block pr-1" />
                Zrušit přihlášení a vrátit se zpět
              </button>
            </RedirectForm>
          </div>
        )}
      </div>
      <div className="sm:text-right text-black-6">© Air Bank a.s.</div>
    </footer>
  )
}

export default Footer
