import { FC } from "react"
import FormattedSignRow from "./formatted-sign-row"

export type SignObjectEntry = {
  key: string
  value: string
}

type ObjectSignData = {
  signObjectHash: string
  signObjectEntries: SignObjectEntry[]
}

export type FormattedObjectSignProps = {
  data?: Partial<ObjectSignData>
}

const FormattedObjectSign: FC<FormattedObjectSignProps> = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { signObjectEntries, signObjectHash } = data

  return (
    <div className="p-5 pb-2 flex flex-col text-xs mb-3 border border-solid border-black-da rounded-md">
      {signObjectEntries && (
        <FormattedSignRow label="Podepisovaný obsah">
          <div className="font-medium">
            {signObjectEntries.map(entry => {
              return (
                <p className="pb-3" key={entry.key}>
                  {entry.key}
                  {entry.value && (
                    <span className="inline-block font-bold">
                      &nbsp;- {entry.value}
                    </span>
                  )}
                </p>
              )
            })}
          </div>
        </FormattedSignRow>
      )}
      {signObjectHash && (
        <FormattedSignRow label="Otisk prohlášení">
          {signObjectHash}
        </FormattedSignRow>
      )}
    </div>
  )
}

export default FormattedObjectSign
