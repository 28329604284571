import axios, { AxiosResponse } from "axios"

import { FilledBearerToken } from "../../state/global"

export type ResendSmsRequest = {
  sessionId: string
  bearerToken: FilledBearerToken
}

export type ResendSmsResponse = AxiosResponse<Record<string, never>>

interface ExecResendSms<T> {
  (data: T): Promise<ResendSmsResponse>
}

const execResendSms: ExecResendSms<ResendSmsRequest> = data => {
  const { sessionId, bearerToken } = data

  return axios.post(
    `/api/apa/v1/authentication/resendSms?${sessionId}`,
    {},
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )
}

export default execResendSms
