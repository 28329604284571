import React, { FC } from "react"

import MobileApp from "../../assets/mobile-app.png"

const SWTOffline: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Jak vytvořím kód v mobilní aplikaci?
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-x-8">
      <img src={MobileApp} alt="mobilní aplikace" />
      {/* TODO a better resolution image would be wonderful */}
      <ul className="ul md:col-start-2 md:col-span-1 space-y-4 text-sm mb-6 sm:mb-0">
        <li>
          <p>
            V&nbsp;mobilu si&nbsp;<strong>pusťte aplikaci My&nbsp;Air</strong>.
            Jestli nejste připojeni k&nbsp;internetu, vypadá úvodní obrazovka
            aplikace přibližně tak, jak vidíte na&nbsp;obrázku.
          </p>
        </li>
        <li>
          <p>
            Ťukněte na&nbsp;šedé tlačítko <strong>Bezpečnostní kód</strong>{" "}
            a&nbsp;zvolte, že&nbsp;se&nbsp;chcete přihlásit, potvrdit operaci
            nebo podepsat dokument.
          </p>
        </li>
        <li>
          <p>
            Spustí&nbsp;se fotoaparát.{" "}
            <strong>Ofoťte QR kód a&nbsp;zadejte heslo</strong> do&nbsp;mobilní
            aplikace. Tam vám pak ukážeme číselný kód, který{" "}
            <strong>
              přepíšete do&nbsp;kolonky{" "}
              <span className="hidden sm:inline">vpravo</span>
              <span className="sm:hidden">výše</span>
            </strong>
            .
          </p>
        </li>
        <li>
          <p>
            <strong>Ale pozor</strong>, nějaký kód vám ukážeme vždycky, i když
            zadáte špatné heslo do mobilní aplikace. Ten správný vám ukážeme,
            jen když heslo do mobilní aplikace napíšete správně.
          </p>
        </li>
      </ul>
    </div>
  </>
)

export default SWTOffline
