import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  useState,
} from "react"
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form"
import { v4 } from "uuid"

import { ReactComponent as Check } from "../../../assets/check.svg"

import styles from "./index.module.css"

interface Props {
  label?: string
  checked?: boolean
  className?: string
  labelTextClassName?: string
}

function Checkbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    Props
): ReactElement {
  const { field } = useController<TFieldValues, TName & string>(props)
  const [id] = useState(v4())

  const { label, className, disabled, labelTextClassName } = props

  const { onChange, onBlur } = field

  return (
    <label htmlFor={id} className={className} data-testid="checkbox">
      <div
        className={`${styles.box} border border-solid border-green-brand w-4 h-4 flex flex-shrink-0 items-center justify-center`}
      >
        <input
          {...field}
          id={id}
          type="checkbox"
          checked={!!field.value}
          onChange={e => onChange(e.target.checked)}
          onBlur={() => onBlur()}
          disabled={disabled}
          className="hidden"
          data-testid={`input-checkbox-${field.name}`}
        />
        {!!field.value && <Check data-testid={`checkbox-icon-${field.name}`} />}
      </div>
      {label && <div className={labelTextClassName}>{label}</div>}
    </label>
  )
}

export default Checkbox
