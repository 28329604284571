import { selector } from "recoil"

import { aPhase } from "./global"
import { aLoginStep } from "./login-step"

/**
 * Consolidated logic governing the display of QR component
 */
const sShouldShowQR = selector({
  key: "sShouldShowQR",
  get: ({ get }) => {
    const phase = get(aPhase)
    const { nextAuthorizationStep } = get(aLoginStep)

    return (
      phase === "qrInit" ||
      (phase === "login" && nextAuthorizationStep === "USERNAME")
    )
  },
})

export default sShouldShowQR
