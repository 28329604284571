import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useEffect } from "react"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import {
  aPhase,
  aServiceProvider,
  ServiceProvider,
  sIsServiceProviderBankId,
  sIsServiceProviderNIA,
} from "../state/global"
import { aApaSessionId } from "../state/auxiliary"
import { aIdPortState, aIdPortToken, aIdPortTraceState } from "../state/idport"
import { sHandleLoginError, sProcessPreLogin } from "../state/management"
import { execPreLogin, PreLoginRequest } from "../requests/pre-login"

const useHandlePreLogin = (): void => {
  const handleError = useErrorHandler()
  const phase = useRecoilValue(aPhase)
  const serviceProvider = useRecoilValue(
    aServiceProvider
  ) as NonNullable<ServiceProvider>
  const isServiceProviderNIA = useRecoilValue(sIsServiceProviderNIA)
  const isServiceProviderBankId = useRecoilValue(sIsServiceProviderBankId)
  const apaSessionId = useRecoilValue(aApaSessionId)
  const token = useRecoilValue(aIdPortToken) as string // TODO check type hinting and restrictions
  const state = useRecoilValue(aIdPortState) as string
  const tracestate = useRecoilValue(aIdPortTraceState) as string
  const processPrelogin = useSetRecoilState(sProcessPreLogin)
  const [, handleLoginError] = useRecoilState(sHandleLoginError)

  useEffect(() => {
    const handlePreLogin = async (data: PreLoginRequest) => {
      try {
        const result = await execPreLogin(data)

        processPrelogin(result)
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.status === 500 &&
          error.response?.data.error === "100" &&
          error.response?.data.message ===
            "100: Failed to retrieve token info from STS"
        ) {
          handleLoginError({
            loginPhaseError: "TOKEN_INVALID",
            ibUrl: "",
          })
          return
        }

        if (axios.isCancel(error)) {
          return
        }

        handleError(error)
      }
    }

    if (phase !== "preLogin") {
      return
    }

    if (!isServiceProviderNIA && !isServiceProviderBankId) {
      return
    }

    if (!token) {
      return
    }

    handlePreLogin({
      appId: serviceProvider,
      apaSessionId,
      token1: token,
      state,
      tracestate,
    })
  }, [
    apaSessionId,
    handleError,
    handleLoginError,
    isServiceProviderBankId,
    isServiceProviderNIA,
    phase,
    processPrelogin,
    serviceProvider,
    state,
    token,
    tracestate,
  ])
}

export default useHandlePreLogin
