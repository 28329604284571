import { FC } from "react"
import ErrorToSend from "./error-to-send"

const GlobalErrorGeneral: FC = () => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">
        V&nbsp;aplikaci nastala chyba, se&nbsp;kterou jsme nepočítali
      </h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="text-sm">
        <strong>Padá&nbsp;to? Ať&nbsp;to dopadne i&nbsp;na&nbsp;nás.</strong>
      </p>
      <p className="mt-4 text-sm">
        Moc&nbsp;se vám omlouváme, ale&nbsp;v&nbsp;aplikaci nastala chyba,
        se&nbsp;kterou jsme nepočítali. Abychom ji mohli co nejrychleji opravit,
        pomůže nám, když&nbsp;nám o&nbsp;ní napíšete na&nbsp;
        <a
          data-testid="link-email"
          href="mailto:info@airbank.cz"
          className="underline hover:no-underline"
        >
          info@airbank.cz
        </a>
        . Do&nbsp;zprávy prosím uveďte následující údaje:
        <br />
      </p>
      <ErrorToSend />
    </>
  )
}

export default GlobalErrorGeneral
