import React, { FC } from "react"

const PwdAndSms: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Nyní zadejte prosím heslo a&nbsp;kód z&nbsp;SMS
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Při zadávání hesla dejte pozor, jestli nemáte zapnutý{" "}
      <strong>CapsLock</strong> (přepínač velkých písmen) a{" "}
      <strong>NumLock</strong> (přepínač číslic), případně nastavenou
      cizojazyčnou klávesnici. Pro kontrolu, že znaky hesla jsou opravdu
      správně, můžete využít i virtuální klávesnici pod ikonou v kolonce pro
      heslo.{/*  Is the part about keyboard true? */}
    </p>
  </>
)

export default PwdAndSms
