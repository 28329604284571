import { FC } from "react"
import FormattedSignRow from "./formatted-sign-row"
import { formatDate } from "./utils"

type RequestBlockData = {
  providerName: string
  appName: string
  id: string // sign id
  createdDate: string
  signRequestId: string
}

export type FormattedRequestBlockProps = {
  data: Partial<RequestBlockData>
}

const FormattedRequestBlock: FC<FormattedRequestBlockProps> = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { providerName, appName, createdDate, signRequestId } = data

  const formattedCreatedDate = formatDate(createdDate)

  return (
    <div className="p-5 pb-2 flex flex-col text-xs mb-3 border border-solid border-black-da rounded-md">
      {providerName && (
        <FormattedSignRow label="Název obchodníka">
          {providerName}
        </FormattedSignRow>
      )}
      {appName && (
        <FormattedSignRow label="Název služby">{appName}</FormattedSignRow>
      )}
      {formattedCreatedDate && (
        <FormattedSignRow label="Datum vytvoření">
          {formattedCreatedDate}
        </FormattedSignRow>
      )}
      <FormattedSignRow label="Metoda">SHA512</FormattedSignRow>
      {signRequestId && (
        <FormattedSignRow label="ID požadavku">
          {signRequestId}
        </FormattedSignRow>
      )}
    </div>
  )
}

export default FormattedRequestBlock
