interface StringToArrayBuffer {
  (input: string, mode?: "binary"): ArrayBuffer
}

/**
 * Converts string to an ArrayBuffer, default mode as binary
 *
 * Used to
 * - prepare public RSA key for import
 * - convert password to ArrayBuffer for encryption
 */
const stringToArrayBuffer: StringToArrayBuffer = (input, mode) => {
  const preparedInput =
    mode === "binary" ? window.atob(input.replace(/\s/g, "")) : input

  // prepare array buffer to be filled with data from the string
  const arrayBuffer = new ArrayBuffer(preparedInput.length)
  const binaryData = new Uint8Array(arrayBuffer)

  // fill the buffer with data from the key
  for (let i = 0; i < preparedInput.length; i += 1) {
    binaryData[i] = preparedInput.charCodeAt(i)
  }

  return arrayBuffer
}

interface ArrayBufferToBase64 {
  (buffer: ArrayBuffer): string
}

/**
 * Converts an ArrayBuffer to binary string and to base64
 *
 * This is used to encode the buffer resulting from `window.crypto` encrypt()
 * to be sent to the backend
 */
const arrayBufferToBase64: ArrayBufferToBase64 = buffer => {
  const binary = String.fromCharCode.apply(null, [...new Uint8Array(buffer)])

  return window.btoa(binary)
}

export { arrayBufferToBase64, stringToArrayBuffer }
