/** @jsxRuntime classic */
import "react-app-polyfill/ie11"
import "core-js/stable"
import "new-event-polyfill"

// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import React, { FC, StrictMode } from "react"
import { RecoilRoot } from "recoil"
import { render } from "react-dom"

import reportWebVitals from "./reportWebVitals"

import App from "./app"
import CacheBuster from "./components/cache-buster"
import { version } from "../package.json"
import Overlay from "./components/overlay"

import "./styles/index.css"

const renderApp = (RecoilDebug: FC = () => null) => {
  const isProduction = process.env.NODE_ENV === "production"
  render(
    <StrictMode>
      <RecoilRoot>
        <RecoilDebug />
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction}
          verbose={false} // If true, the library writes verbose logs to console.
          loadingComponent={<Overlay />}
        >
          <App />
        </CacheBuster>
      </RecoilRoot>
    </StrictMode>,
    document.getElementById("root")
  )
}

;(async () => {
  let RecoilDebug: FC = () => null

  if (process.env.NODE_ENV !== "development") {
    renderApp(RecoilDebug)
    return
  }

  if (process.env.REACT_APP_DEBUG) {
    RecoilDebug = (await import("./components/recoil-debug")).default
  }

  if (!process.env.REACT_APP_BE) {
    const makeServer = (await import("./server")).default

    makeServer({
      environment: process.env.NODE_ENV,
      operationType:
        process.env.REACT_APP_OPERATION_TYPE === "SIGN"
          ? "DOCUMENT_SIGN"
          : "AUTHENTICATION",
    })
  }

  renderApp(RecoilDebug)
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
