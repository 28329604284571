import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useEffect } from "react"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import {
  aBearerToken,
  aPhase,
  aOperationType,
  FilledBearerToken,
  sIsServiceProviderBankId,
  sIsServiceProviderNIA,
} from "../state/global"
import { aApaSessionId } from "../state/auxiliary"
import { aRedirectUrl } from "../state/login-step"
import {
  aConsents,
  aIdPortState,
  aIdPortToken,
  aIdPortTraceState,
} from "../state/idport"
import { execPostLogin, PostLoginRequest } from "../requests/post-login"

const useHandlePostLogin = (): void => {
  const handleError = useErrorHandler()
  const [phase, setPhase] = useRecoilState(aPhase)
  const isServiceProviderNIA = useRecoilValue(sIsServiceProviderNIA)
  const isServiceProviderBankId = useRecoilValue(sIsServiceProviderBankId)
  const apaSessionId = useRecoilValue(aApaSessionId)
  const setToken = useSetRecoilState(aIdPortToken)
  const setState = useSetRecoilState(aIdPortState)
  const setTracestate = useSetRecoilState(aIdPortTraceState)
  const setRedirectUrl = useSetRecoilState(aRedirectUrl)
  const setConsents = useSetRecoilState(aConsents)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const operationType = useRecoilValue(aOperationType)

  useEffect(() => {
    const handlePostLogin = async (data: PostLoginRequest) => {
      try {
        const { consents, redirect } = await execPostLogin(data)

        /**
         * Ends the postLogin flow and redirects the user
         */
        if (redirect) {
          const { redirectUrl, state, token3, tracestate } = redirect
          setToken(token3)
          setState(state)
          setTracestate(tracestate)
          setRedirectUrl(redirectUrl)
          setPhase("redirect")
        }

        /**
         * Continues the postLogin flow with consent approval
         */
        if (consents) {
          setConsents(consents)
          setPhase("consents")
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return
        }

        handleError(error)
      }
    }

    if (phase === "postLogin") {
      if (isServiceProviderNIA || isServiceProviderBankId) {
        handlePostLogin({
          apaSessionId,
          bearerToken,
        })
      }
    }
  }, [
    apaSessionId,
    bearerToken,
    operationType,
    handleError,
    isServiceProviderBankId,
    isServiceProviderNIA,
    phase,
    setConsents,
    setPhase,
    setRedirectUrl,
    setState,
    setToken,
    setTracestate,
  ])
}

export default useHandlePostLogin
