import React, { FC, useEffect, useState } from "react"
import { useMedia } from "react-use"
import QRCode from "qrcode.react"

import useHandleQr from "../../hooks/use-handle-qr"
import Button from "../ui/button"

const QR: FC = () => {
  const isMobile = useMedia("(max-width: 768px)")
  const [buttonVisible, setButtonVisible] = useState(false)
  const [qrVisible, setQRVisible] = useState(true)

  useEffect(() => {
    setButtonVisible(isMobile)
    buttonVisible ? setQRVisible(false) : setQRVisible(true)
  }, [isMobile, buttonVisible])

  const handleLinkToQR = () => setQRVisible(true)

  const { qrCode } = useHandleQr()

  if (!qrCode) {
    return null
  }

  return (
    <div className="flex flex-col mt-auto">
      <p className="text-xs mt-6 mb-4 sm:mt-0">
        {buttonVisible && !qrVisible ? (
          <strong className="block mt-6">
            Přímá cesta k&nbsp;přihlášení pomocí aplikace My&nbsp;Air
          </strong>
        ) : (
          <>
            <strong className="block">Rychlejší cesta k přihlášení</strong>
            Stačí vyfotit QR kód a potvrdit v aplikaci
          </>
        )}
      </p>
      {buttonVisible && !qrVisible ? (
        <>
          <Button
            data-testid="button-login-to-myair"
            variant="white"
            className="mx-auto mb-4 px-6"
            href={qrCode}
          >
            <span className="px-1">Přihlásit se přes aplikaci My Air</span>
          </Button>
          <button
            onClick={handleLinkToQR}
            className="text-xs underline hover:no-underline"
          >
            Zobrazit QR kód
          </button>
        </>
      ) : (
        <div
          className="flex justify-center mt-2 p-5 bg-white rounded-lg"
          data-testid="qr-code"
        >
          <QRCode value={qrCode} size={200} renderAs="svg" />
        </div>
      )}
    </div>
  )
}

export default QR
