import React, { FC } from "react"

import { LoginStep } from "../../../../state/login-step"

import Default from "./default"
import WithPwd from "./with-pwd"
import WithPwdAndSms from "./with-pwd-and-sms"
import WithSMS from "./with-sms"

type Props = {
  nextAuthorizationStep: LoginStep["nextAuthorizationStep"]
}

const Content: FC<Props> = ({ nextAuthorizationStep }) => {
  if (nextAuthorizationStep === "PWD_AND_SMS") {
    return <WithPwdAndSms />
  }

  if (nextAuthorizationStep === "SMS") {
    return <WithSMS />
  }

  if (nextAuthorizationStep === "PWD") {
    return <WithPwd />
  }

  return <Default />
}

export default Content
