import React, { FC, MouseEventHandler } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"

import styles from "./index.module.css"

interface ButtonProps {
  href?: string
  type?: "button" | "submit"
  variant?: "black" | "white" | "green"
  disabled?: boolean
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  testId?: string
  form?: string
}

const Button: FC<ButtonProps> = ({
  children,
  href = "",
  type = "button",
  variant = "black",
  disabled = false,
  onClick,
  testId = "button",
  form,
  className,
}) => {
  const css = classNames(
    className,
    variant === "white" ? styles.buttonWhite : styles.buttonBlack,
    variant === "black" ? "text-white" : "text-black",
    variant === "green" ? styles.buttonGreen : styles.buttonBlack,
    variant === "green"
      ? "border-solid border border-green-highlight hover:border-transparent"
      : null,
    variant === "white"
      ? "border-solid border border-black-da hover:border-black-b4"
      : null,

    "px-2",
    "leading-10 text-xs font-bold text-center",
    "bg-no-repeat bg-left-top",
    "rounded-md",
    "cursor-pointer",
    "focus:outline-none hover:ease-in-out"
  )

  const getButton = (
    <button
      type={type}
      className={css}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      form={form}
    >
      {children}
    </button>
  )

  if (href?.startsWith("http")) {
    return (
      <a href={href} className={css} data-testid={`${testId}-link`}>
        {children}
      </a>
    )
  }

  if (href) {
    return (
      <Link to={href} className="contents" data-testid={`${testId}-link`}>
        {getButton}
      </Link>
    )
  }

  return <>{getButton}</>
}

export default Button
