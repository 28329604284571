import { FC } from "react"
import ErrorToSend from "./error-to-send"

const GlobalErrorNetwork: FC = () => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">
        Tak tohle se nám nepovedlo
      </h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="mt-4 text-sm">
        Omlouváme&nbsp;se vám, vypadá&nbsp;to, že&nbsp;naše technika nestačila
        udělat všechno tak, jak&nbsp;měla. Zkuste&nbsp;to prosím ještě jednou.
        Pokud&nbsp;to dopadne stejně, narazili jste na&nbsp;chybu, o&nbsp;které
        možná nevíme.
      </p>
      <p className="mt-4 text-sm">
        Abychom ji mohli opravit, pomůže nám, když&nbsp;nám na&nbsp;adresu{" "}
        <a
          data-testid="link-email"
          href="mailto:info@airbank.cz"
          className="underline hover:no-underline"
        >
          info@airbank.cz
        </a>{" "}
        pošlete toto:
      </p>
      <ErrorToSend />
    </>
  )
}

export default GlobalErrorNetwork
