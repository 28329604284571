import React, { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import { aBearerToken, aLoading, FilledBearerToken } from "../../state/global"
import { aApaSessionId } from "../../state/auxiliary"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "../../hooks/use-process-authentication-response"
import { execLoginAttempt } from "../../requests/authentication"

import Button from "../ui/button"
import ResendSms from "./resend-sms"
import TextField from "../ui/text-field/index"

interface Form {
  SMS: string
}

const Sms: FC = () => {
  const { control, handleSubmit } = useForm<Form>({
    mode: "onSubmit",
  })

  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken

  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()

  useProcessAuthenticationResponse(fetchResult)

  const onSubmit: SubmitHandler<Form> = async data => {
    setLoading(true)

    try {
      const attemptResult = await execLoginAttempt({
        sessionId,
        bearerToken,
        ...data,
      })

      setFetchResult(attemptResult)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="SMS"
        label="Kód z SMS"
        defaultValue=""
        rules={{
          required: "Toto pole je povinné",
        }}
        type="password"
        autoFocus
        autoComplete="off"
      />

      <Button
        testId="button-continue"
        type="submit"
        className="w-full mt-6"
        disabled={isLoading}
      >
        Pokračovat
      </Button>

      <ResendSms className="mt-4" />
    </form>
  )
}

export default Sms
