import React, { FC, useEffect, useRef } from "react"
import { useRecoilValue } from "recoil"

import { aApaSessionId } from "../state/auxiliary"
import { aRedirectUrl } from "../state/login-step"
import {
  aIdPortState,
  aIdPortToken,
  aIdPortTraceState,
  IdPortError,
} from "../state/idport"

interface Props {
  autoSubmit?: boolean
  errorCode?: IdPortError
  action?: "ERROR" | "OK" | "CANCEL"
}

const RedirectForm: FC<Props> = ({
  autoSubmit = true,
  errorCode,
  action = "ERROR",
  children,
}) => {
  const sessionId = useRecoilValue(aApaSessionId)
  const idPortToken = useRecoilValue(aIdPortToken) as string
  const idPortState = useRecoilValue(aIdPortState) as string
  const idPortTraceState = useRecoilValue(aIdPortTraceState) as string
  const redirectUrl = useRecoilValue(aRedirectUrl) as string

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (autoSubmit) {
      formRef?.current?.submit()
    }
  }, [autoSubmit, formRef])

  return redirectUrl ? (
    <form
      ref={formRef}
      method="post"
      action={`${redirectUrl}?${sessionId}`}
      encType="application/x-www-form-urlencoded"
      data-testid="redirect-form"
    >
      {action === "ERROR" && (
        <input
          data-testid="input-redirect-form-errorCode"
          type="hidden"
          name="errorCode"
          value={errorCode}
        />
      )}
      {(action === "OK" || action === "CANCEL") && (
        <input
          data-testid="input-redirect-form-token"
          type="hidden"
          name="token"
          value={idPortToken}
        />
      )}
      <input
        data-testid="input-redirect-form-action"
        type="hidden"
        name="action"
        value={action}
      />
      <input
        data-testid="input-redirect-form-state"
        type="hidden"
        name="state"
        value={idPortState}
      />
      <input
        data-testid="input-redirectform-tracestate"
        type="hidden"
        name="tracestate"
        value={idPortTraceState}
      />
      {children}
    </form>
  ) : null
}

export default RedirectForm
