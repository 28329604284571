import React, { FC } from "react"
import { useRecoilValue } from "recoil"

import {
  aLoginStep,
  sHasLoginStarted,
  sShouldShowSWT,
} from "../../state/login-step"
import {
  aSwtOnlineDeviceName,
  aSwtOnlineValidityInSeconds,
} from "../../state/swt"

import UserName from "./user-name"
import Birthday from "./birthday"
import Password from "./password"
import PasswordAndSms from "./password-and-sms"
import SWTOnline from "./swt-online"
import SWTOffline from "./swt-offline"
import SMS from "./sms"

const LoginForm: FC = () => {
  const { nextAuthorizationStep } = useRecoilValue(aLoginStep)
  const hasLoginStarted = useRecoilValue(sHasLoginStarted)
  const showSWT = useRecoilValue(sShouldShowSWT)
  const swtOnlineDeviceName = useRecoilValue(aSwtOnlineDeviceName) as string
  const swtOnlineValidityInSeconds = useRecoilValue(
    aSwtOnlineValidityInSeconds
  ) as number

  if (!hasLoginStarted) {
    return <UserName />
  }

  if (nextAuthorizationStep === "BIRTHDAY") {
    return <Birthday />
  }

  if (nextAuthorizationStep === "PWD") {
    return <Password />
  }

  if (nextAuthorizationStep === "PWD_AND_SMS") {
    return <PasswordAndSms />
  }

  if (showSWT) {
    return (
      <SWTOnline
        swtOnlineDeviceName={swtOnlineDeviceName}
        swtOnlineValidityInSeconds={swtOnlineValidityInSeconds}
      />
    )
  }

  if (nextAuthorizationStep === "SWT_OFFLINE") {
    return <SWTOffline />
  }

  if (nextAuthorizationStep === "SMS") {
    return <SMS />
  }

  return null
}

export default LoginForm
