import { FC } from "react"

const RedirectFail: FC = () => (
  <div className="h-full sm:absolute sm:left-0 sm:top-0 sm:m-0 -m-5 p-5 bg-green-brand">
    <h2 className="font-bold text-2xl">Přesměrování neproběhlo?</h2>
    <hr className="mt-4 mb-6 border-black-full" />
    <p className="text-xs">
      Zřejmě jste se dostali do&nbsp;situace, která&nbsp;není úplně obvyklá.
      Pokud si nevíte rady, poradíme vám.
    </p>
    <p className="mt-4 text-xs">
      <strong>Kde&nbsp;vám pomůžeme?</strong>
    </p>
    <ul className="list-disc text-xs mx-5 my-1">
      <li>
        <div>
          na&nbsp;telefonu{" "}
          <strong>
            <a data-testid="link-phone-number" href="tel:515202202">
              515&nbsp;202&nbsp;202
            </a>
          </strong>
        </div>
      </li>
      <li>
        <div>
          na&nbsp;kterékoliv{" "}
          <a
            data-testid="link-map-of-branches"
            href="https://www.airbank.cz/mapa-pobocek-a-bankomatu/"
            className="underline hover:no-underline"
          >
            pobočce
          </a>
        </div>
      </li>
    </ul>
  </div>
)

export default RedirectFail
