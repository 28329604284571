import React, { FC } from "react"

const NiaOptedOut: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Potřebujeme váš souhlas k&nbsp;používání bankovní identity
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Abychom mohli pokračovat,{" "}
      <strong>
        potvrďte nám prosím ve&nbsp;svém internetovém bankovnictví,
        že&nbsp;chcete své bezpečnostní prvky používat pro elektronické
        ověřování totožnosti
      </strong>{" "}
      jak při jednání se&nbsp;státními úřady, tak vybranými soukromými
      společnosti.
    </p>
    <p className="mt-4 text-sm">
      Pro udělení souhlasu k&nbsp;používání bankovní identity se&nbsp;stačí
      přihlásit do&nbsp;internetového bankovnictví pomocí tlačítka &ldquo;Chci
      bankovní identitu&rdquo;.
    </p>
  </>
)

export default NiaOptedOut
