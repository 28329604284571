import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  useState,
  createRef,
  useRef,
  ChangeEvent,
  forwardRef,
  ForwardedRef,
} from "react"
import { useClickAway, useToggle } from "react-use"
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form"
import { v4 } from "uuid"
import classNames from "classnames"

import InfoTooltip from "../info-tooltip"
import VirtualKeyboard, {
  KeyboardInstanceInterface,
} from "../../virtual-keyboard"

import { ReactComponent as KeyboardIcon } from "../../../assets/keyboard.svg"

import styles from "./index.module.css"

interface Props {
  autoFocus?: boolean
  label?: string
  type?: "text" | "number" | "password"
  className?: string
  errorText?: string
  hasVirtualKeyboard?: boolean
  inputTestId?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const TextField = forwardRef(
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >(
    props: UseControllerProps<TFieldValues, TName> &
      DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      > &
      Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { field, fieldState } = useController<TFieldValues, TName & string>(
      props
    )
    const {
      autoFocus,
      className,
      errorText,
      hasVirtualKeyboard = false,
      label,
      placeholder,
      onChange,
      type = "text",
      inputTestId,
    } = props

    const id = v4()
    const { onBlur: hookFormOnBlur, onChange: hookFormOnChange } = field

    // Error tooltip implementation
    const [focus, setFocus] = useState(false)
    const [errorHidden, hideError] = useState(true)

    const errorTooltipRef = createRef<HTMLDivElement>()
    useClickAway(errorTooltipRef, () => hideError(true))

    // Virtual keyboard implementation
    const keyboardRef = useRef<KeyboardInstanceInterface | null>(null)
    const [keyBoardVisible, toggleKeyBoardVisible] = useToggle(false)

    // General change handler (virtual keyboard aware)
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      // sets react-simple-keyboard value
      keyboardRef.current?.setInput(value)

      // sets react-hook-form value
      hookFormOnChange(value)

      // finally - handle change passed in props
      onChange?.(event)
    }

    const inputWrapperCss = classNames(
      styles["input-wrapper"],
      "flex flex-row justify-between",
      "px-2 py-2",
      "bg-no-repeat bg-left-top bg-input-bg",
      "border border-solid",
      "rounded-md",
      "shadow-input",
      {
        "border-input-border": !fieldState.invalid,
        "border-error-light":
          (fieldState.isDirty || fieldState.invalid) && !focus,
        "border-error-base":
          (fieldState.isDirty || fieldState.invalid) && focus,
      }
    )

    const inputCss = classNames(
      "w-full",
      "outline-none",
      "bg-transparent",
      "text-xs",
      styles["no-spin-button"],
      {
        "text-black-4": !fieldState.isTouched && !fieldState.isDirty,
        "text-black": fieldState.isTouched || fieldState.isDirty,
        "text-error-base": fieldState.invalid,
      }
    )

    return (
      <div className={className} data-testid="text-field">
        <label
          htmlFor={id}
          className="font-bold text-xs leading-6 whitespace-no-wrap"
        >
          {label}
        </label>
        <div className={inputWrapperCss} data-testid="styled-input-wrapper">
          <div className="flex-grow leading-none">
            <input
              {...field}
              id={id}
              type={type}
              // eslint-disable-next-line
              autoFocus={autoFocus}
              placeholder={placeholder}
              className={inputCss}
              onChange={onInputChange}
              onFocus={() => {
                setFocus(true)
              }}
              onBlur={() => {
                setFocus(false)
                hookFormOnBlur()
                hideError(!hideError)
              }}
              onError={() => hideError(false)}
              ref={ref}
              data-testid={inputTestId}
            />
          </div>
          {hasVirtualKeyboard && (
            <button
              type="button"
              className="ml-2 flex-end"
              onClick={toggleKeyBoardVisible}
              data-testid="button-keyboard"
            >
              <KeyboardIcon />
            </button>
          )}
        </div>
        {hasVirtualKeyboard && keyBoardVisible ? (
          <div className="absolute right-0 shadow-lg mt-1 mr-6 z-10">
            <div className="relative">
              <VirtualKeyboard
                keyboardRef={keyboardRef}
                currentValue={field.value}
                onChange={hookFormOnChange}
              />
              <button
                type="button"
                onClick={toggleKeyBoardVisible}
                className={`absolute right-0 top-0 ${styles["virtual-keyboard-close"]}`}
                aria-label="Zavřít klávesnici"
                data-testid="button-keyboard-close"
              />
            </div>
          </div>
        ) : null}

        {fieldState.error?.type === "required" && !errorHidden && (
          <>
            <InfoTooltip
              ref={errorTooltipRef}
              infoText={errorText}
              variant="error"
              onCloseError={() => {
                hideError(true)
              }}
              data-testid="info-tooltip"
            />
          </>
        )}
      </div>
    )
  }
)

export default TextField as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    Props & { ref?: ForwardedRef<HTMLInputElement> }
) => ReactElement
