import React, { FC } from "react"
import { useRecoilValue } from "recoil"

import {
  aLoginPhaseError,
  aRedirectUrl,
  LoginPhaseError,
} from "../../state/login-step"

import Button from "../ui/button"
import RedirectForm from "../redirect-form"
import ContactUs from "../global-error/contact-us"
import RedirectFail from "../global-error/redirect-fail"

// Errors related to login-form flow (invalid pwd etc.)
const LoginError: FC = () => {
  const error = useRecoilValue(aLoginPhaseError) as NonNullable<LoginPhaseError>
  const redirectUrl = useRecoilValue(aRedirectUrl)

  if (error === "NIA_OPTED_OUT") {
    return (
      <Button
        testId="button-want-bankid"
        href="https://ib.airbank.cz/?goTo=sbi"
        className="mt-10"
      >
        Chci bankovní identitu
      </Button>
    )
  }

  if (error === "TOKEN_INVALID") {
    return <RedirectFail />
  }

  if (!redirectUrl || error === "REDIRECT_URL_EXPIRED") {
    return <ContactUs />
  }

  if (error === "REJECTED_SWT_ONLINE") {
    return (
      <RedirectForm action="CANCEL" autoSubmit={false}>
        <Button type="submit" className="mt-10 w-full">
          Vraťte mne zpátky
        </Button>
      </RedirectForm>
    )
  }

  if (
    ["QR_ERROR", "QR_BLOCKED", "QR_INVALID_TOKEN", "TIMEOUTED"].includes(error)
  ) {
    return <RedirectForm autoSubmit action="CANCEL" />
  }

  if (
    [
      "NO_AUTHORIZATION_METHOD",
      "NIA_NOT_IDENTIFIED",
      "QR_REJECTED",
      "REJECTED_BLOCK",
    ].includes(error)
  ) {
    return (
      <RedirectForm action="CANCEL" autoSubmit={false}>
        <Button
          testId="button-try-again"
          type="submit"
          className="mt-10 w-full"
        >
          Začít znovu
        </Button>
      </RedirectForm>
    )
  }

  return null
}

export default LoginError
