import React, { FC, MutableRefObject } from "react"
import { useToggle } from "react-use"
import Keyboard, { KeyboardReactInterface } from "react-simple-keyboard"

import "react-simple-keyboard/build/css/index.css"

import "./theme.css"

const layout = {
  default: [
    "[@ # $ ~ ^ & % ( ) {bksp}] [7 8 9 /]",
    "[q w e r t z u i o p _] [4 5 6 *]",
    "[{lock} a s d f g h j k l ; !] [1 2 3 -]",
    "[y x c v b n m , .] [0 | = +]",
  ],
  caps: [
    "[@ # $ ~ ^ & % ( ) {bksp}] [7 8 9 /]",
    "[Q W E R T Z U I O P _] [4 5 6 *]",
    "[{lock} A S D F G H J K L ; !] [1 2 3 -]",
    "[Y X C V B N M , .] [0 | = +]",
  ],
}

const display = {
  "{bksp}": " ",
  "{lock}": " ",
}

const buttonAttributes = [
  {
    attribute: "type",
    value: "button",
    buttons:
      "@ # $ ~ ^ & % ( ) {bksp} 7 8 9 / q w e r t z u i o p _ 4 5 6 * {lock} a s d f g h j k l ; ! 1 2 3 - y x c v b n m , . 0 | = + Q W E R T Z U I O P A S D F G H J K L Y X C V B N M",
  },
]

const buttonTheme = [
  {
    class: "invisible",
    buttons: "|",
  },
]

// defined to avoid installing simple-keyboard as a dependency
export type KeyboardInstanceInterface = {
  setInput: (input: string) => void
} & KeyboardReactInterface

interface Props {
  currentValue: string
  keyboardRef: MutableRefObject<KeyboardInstanceInterface | null>
  onChange: (input: string) => void
}

const VirtualKeyboard: FC<Props> = ({
  currentValue,
  keyboardRef,
  onChange,
}) => {
  const [isCapsLock, toggleCapsLock] = useToggle(false)

  const ref = keyboardRef

  const onKeyPress = (button: string) => {
    if (button === "{lock}") {
      toggleCapsLock()
    }
  }

  return (
    <Keyboard
      keyboardRef={(r: KeyboardInstanceInterface) => {
        ref.current = r
      }}
      theme="virtual-keyboard"
      layoutName={isCapsLock ? "caps" : "default"}
      layout={layout}
      display={display}
      buttonAttributes={buttonAttributes}
      buttonTheme={buttonTheme}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onRender={(keyboard: KeyboardInstanceInterface) =>
        keyboard.setInput(currentValue)
      }
      useButtonTag
      mergeDisplay
      data-testid="virtual-keyboard"
    />
  )
}

export default VirtualKeyboard
