import axios from "axios"

import { FilledBearerToken } from "../../state/global"

/**
 * NOT_OK for cases such as deceased client
 */
export type AuthorizationFinishResult = "OK" | "NOT_OK"

type FinishResponse = {
  result: AuthorizationFinishResult
  redirectUrl: string
  authToken: FilledBearerToken
}

type FinishRequest = {
  sessionId: string
  bearerToken: FilledBearerToken
}

interface ExecLoginFinish {
  (data: FinishRequest): Promise<FinishResponse>
}

const execLoginFinish: ExecLoginFinish = async data => {
  const { sessionId, bearerToken } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
    // TODO
  }

  // TODO handle 400 errors?
  const response = await axios.post<FinishResponse>(
    `/api/apa/v1/authentication/finish?${sessionId}`,
    {},
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )

  // TODO redirectToken ?? for login errors to report error to IDPort?
  const { authToken, result, redirectUrl } = response.data

  return { authToken, result, redirectUrl }
}

export default execLoginFinish
