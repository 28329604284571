import format from "date-fns/format"

export const getPageSuffix: (pageCount: number) => string = pageCount => {
  let pageSuffix = ""
  if (pageCount > 1 && pageCount < 5) {
    pageSuffix = "y"
  }
  if (pageCount === 1) {
    pageSuffix = "a"
  }
  return pageSuffix
}

export const formatDate: (
  dateToFormat: string | number | Date | undefined
) => string | undefined = dateToFormat => {
  if (!dateToFormat) return undefined
  try {
    const date = new Date(dateToFormat)
    return format(date, "d. M. yyyy H:mm:ss")
  } catch (e) {
    return undefined
  }
}
