import axios, { AxiosRequestConfig } from "axios"

import { CancellableRequest } from "./types"
import { AttemptResponse } from "./attempt"
import { FilledBearerToken } from "../../state/global"

export type StatusRequest = CancellableRequest & {
  sessionId: string
  bearerToken: FilledBearerToken
}

type StatusResponse = AttemptResponse

interface ExecStatus {
  (data: StatusRequest): Promise<StatusResponse>
}

const execLoginStatus: ExecStatus = async data => {
  const { sessionId, bearerToken, cancelToken } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
    // TODO
  }

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  // TODO handle 400 errors?
  const response = await axios.post<StatusResponse>(
    `/api/apa/v1/authentication/status?${sessionId}`,
    {},
    config
  )

  const {
    authorizationAttemptResult,
    authorizationStatus,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOnlineValidityInSeconds,
  } = response.data

  return {
    authorizationAttemptResult,
    authorizationStatus,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOnlineValidityInSeconds,
  }
}

export default execLoginStatus
