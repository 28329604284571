import { atom, selector } from "recoil"

import { aSwtOnlineDeviceName, aSwtOnlineValidityInSeconds } from "./swt"

/**
 * Descriptors for Air Bank login flow
 *
 * SWT_ONLINE_CONFIRMATION is push notification login
 * SWT_ONLINE is for QR code login
 */
export type LoginStep = {
  authorizationStatus?:
    | "IN_PROGRESS"
    | "AUTHORIZED"
    | "REJECTED"
    | "TIMEOUTED"
    | "NO_AUTHORIZATION_METHOD"
    | "NIA_NOT_IDENTIFIED"
    | "NIA_OPTED_OUT"
  authorizationAttemptResult:
    | "OK"
    | "PROCEED"
    | "NOK_TRY_AGAIN"
    | "NOK_LAST_ATTEMPT"
    | "NOK_LOGOUT"
    | "NOK_BLOCK"
    | null
  nextAuthorizationStep:
    | "USERNAME"
    | "SWT_ONLINE_CONFIRMATION"
    | "PWD"
    | "SMS"
    | "BIRTHDAY"
    | "SWT_ONLINE"
    | "SWT_ONLINE_BIO"
    | "SWT_OFFLINE"
    | "CONFIRMATION"
    | "PWD_AND_SMS"
    | "PWD_AND_SWT_OFFLINE"
    | "NO_MOBILE_PHONE"
    | "INHERENCE"
    | null
}

/**
 * Stores the current step based on the latest response from backend
 */
export const aLoginStep = atom<LoginStep>({
  key: "aLoginStep",
  default: {
    authorizationAttemptResult: null,
    authorizationStatus: "IN_PROGRESS",
    nextAuthorizationStep: "USERNAME",
  },
})

/**
 * Used to check if the user filled their name in
 */
export const sHasLoginStarted = selector<boolean>({
  key: "sHasLoginStarted",
  get: ({ get }) => {
    const { nextAuthorizationStep } = get(aLoginStep)

    return (
      nextAuthorizationStep !== null && nextAuthorizationStep !== "USERNAME"
    )
  },
})

/**
 * Return url to take the user back after authorization
 */
export const aRedirectUrl = atom<string | undefined>({
  key: "aRedirectUrl",
  default: undefined,
})

type ErrorQR = "QR_REJECTED" | "QR_ERROR" | "QR_BLOCKED" | "QR_TOKEN_INVALID"

export type LoginPhaseError =
  | Extract<
      LoginStep["authorizationStatus"],
      | "NIA_NOT_IDENTIFIED"
      | "NIA_OPTED_OUT"
      | "NO_AUTHORIZATION_METHOD"
      | "TIMEOUTED"
    >
  | "REJECTED_LOGOUT"
  | "REJECTED_BLOCK"
  | "REJECTED_SWT_ONLINE"
  | "TOKEN_INVALID"
  | ErrorQR
  | "REDIRECT_URL_EXPIRED"
  | undefined

export const aLoginPhaseError = atom<LoginPhaseError>({
  key: "aLoginPhaseError",
  default: undefined,
})

/**
 * Consolidated logic governing the display of SWT confirmation component
 */
export const sShouldShowSWT = selector({
  key: "sShouldShowSWT",
  get: ({ get }) => {
    const swtOnlineDeviceName = get(aSwtOnlineDeviceName)
    const swtOnlineValidityInSeconds = get(aSwtOnlineValidityInSeconds)
    const { nextAuthorizationStep } = get<LoginStep>(aLoginStep)

    return (
      /^SWT_ONLINE.*/.test(nextAuthorizationStep || "") &&
      swtOnlineDeviceName &&
      swtOnlineValidityInSeconds
    )
  },
})
