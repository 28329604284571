import React, { FC } from "react"

const WithSMS: FC = () => (
  <ul className="ul mt-4">
    <li>
      <div>
        <b>Kód, který&nbsp;vám dorazil</b> při&nbsp;předchozím pokusu
        o&nbsp;přihlášení, je stále platný.{" "}
        <b>Na&nbsp;nový nečekejte a&nbsp;použijte ten původní.</b>
      </div>
    </li>
  </ul>
)

export default WithSMS
