import React, { FC } from "react"
import { ReactComponent as Loader } from "../assets/loader.svg"

const Overlay: FC = () => (
  <div
    className="fixed z-50 inset-0 flex items-center justify-center bg-white bg-opacity-50"
    data-testid="overlay"
  >
    <Loader className="w-12 h-12 animate-spin" />
  </div>
)

export default Overlay
