import React, { FC } from "react"
import { useRecoilValue } from "recoil"
import { aPreloginProviderName } from "../../state/global"

const UrlExpired: FC = () => {
  const providerName = useRecoilValue(aPreloginProviderName)

  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">
        Nestihli&nbsp;jsme vás&nbsp;ověřit
      </h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="text-sm">
        Vraťte&nbsp;se prosím do&nbsp;aplikace poskytovatele služeb{" "}
        <strong>{providerName}</strong> a&nbsp;pomocí bankovní identity zkuste
        potvrdit svou&nbsp;totožnost ještě&nbsp;jednou.
      </p>
    </>
  )
}

export default UrlExpired
