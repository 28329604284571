import React, { FC } from "react"

import { useRecoilValue } from "recoil"
import { aPhase } from "../../state/global"
import { aRedirectUrl } from "../../state/login-step"
import RedirectForm from "../redirect-form"
import Banner from "../ui/banner"

import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg"

const CancelLoginBanner: FC = () => {
  const phase = useRecoilValue(aPhase)
  const redirectUrl = useRecoilValue(aRedirectUrl)

  return phase === "login" && redirectUrl ? (
    <Banner>
      <RedirectForm autoSubmit={false} action="CANCEL">
        <button type="submit" className="font-bold h-full">
          <ArrowLeft className="inline-block mr-2 align-middle" />
          <span className="align-middle underline">
            Zrušit přihlášení a vrátit se zpět
          </span>
        </button>
      </RedirectForm>
    </Banner>
  ) : null
}

export default CancelLoginBanner
