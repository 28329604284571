import { FC } from "react"
import classNames from "classnames"

type FormattedSignRowProps = {
  label?: string
  noBoldValue?: boolean
}

const FormattedSignRow: FC<FormattedSignRowProps> = ({
  children,
  label,
  noBoldValue,
}) => {
  return children ? (
    <div className="flex flex-row w-full pb-3">
      <div className="w-1/2 md:w-1/3">{label}</div>
      <div
        className={classNames(
          "w-1/2 md:w-2/3 break-words",
          noBoldValue ? "" : "font-bold"
        )}
      >
        {children}
      </div>
    </div>
  ) : null
}

export default FormattedSignRow
