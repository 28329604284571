import React, { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import {
  aBearerToken,
  aServiceProvider,
  aLoading,
  FilledBearerToken,
  aUsername,
} from "../../state/global"
import { aApaSessionId } from "../../state/auxiliary"
import { execLoginInit } from "../../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "../../hooks/use-process-authentication-response"

import Button from "../ui/button"
import TextField from "../ui/text-field/index"

interface Form {
  userName: string
}

const UserName: FC = () => {
  const handleError = useErrorHandler()
  const { control, handleSubmit } = useForm<Form>({
    mode: "onSubmit",
  })
  const setUserName = useSetRecoilState(aUsername)
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const serviceProvider = useRecoilValue(aServiceProvider)
  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()

  useProcessAuthenticationResponse(fetchResult)

  const onSubmit: SubmitHandler<Form> = async data => {
    setLoading(true)

    try {
      const result = await execLoginInit({
        sessionId,
        bearerToken,
        appId: serviceProvider,
        ...data,
      })

      setFetchResult(result)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
      setUserName(data.userName)
    }
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="userName"
        label="Nejdříve zadejte uživatelské jméno"
        defaultValue=""
        rules={{
          required: "Toto pole je povinné",
        }}
        autoComplete="off"
        autoFocus
        inputTestId="input-username"
      />
      <Button
        testId="button-continue"
        type="submit"
        className="w-full mt-6"
        disabled={isLoading}
      >
        Pokračovat
      </Button>
    </form>
  )
}

export default UserName
