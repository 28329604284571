import React, { FC } from "react"

const WithPwd: FC = () => (
  <>
    <p className="text-sm">
      Důvodů může být více. Třeba nezadáváte správné uživatelské jméno,
      překlepli jste&nbsp;se v&nbsp;hesle nebo jsme podle vašeho chování
      vyhodnotili, že&nbsp;to nejste vy.
    </p>
    <strong className="inline-block mt-6 text-sm">
      Pár tipů, v&nbsp;čem by&nbsp;mohl být problém
    </strong>
    <ul className="ul mt-4 space-y-4 text-sm">
      <li>
        <div>
          Nejprve se&nbsp;přesvědčte, že&nbsp;máte správně přepnutý{" "}
          <b>CapsLock</b> (přepínač velkých písmen) a&nbsp;<b>NumLock</b>{" "}
          (přepínač číslic), případně nemáte nastavenou cizojazyčnou klávesnici.
          Pomoci může i&nbsp;promazat historii prohlížeče.
        </div>
      </li>
      <li>
        Ověřte&nbsp;si, jestli jste správně vyplnili uživatelské jméno. Pokud
        ano, zkuste zadat znovu heslo. Uživatelské jméno i&nbsp;heslo zadávejte
        bez mezer.
      </li>
    </ul>
  </>
)

export default WithPwd
