import { useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"

import { aBearerToken, aPhase } from "../state/global"
import { aRsaPublicKey } from "../state/auxiliary"
import { sHandleLoginError } from "../state/management"
import { aLoginPhaseError, aLoginStep } from "../state/login-step"
import {
  aSwtOnlineDeviceName,
  aSwtOnlineValidityInSeconds,
  aSwtOfflineFollowUpData,
} from "../state/swt"

import { InitResponse } from "../requests/authentication/init"
import { AttemptResponse } from "../requests/authentication/attempt"

export type GeneralAuthenticationResponse = Partial<InitResponse> &
  AttemptResponse

interface UseProcessAuthenticationResponse {
  (result?: GeneralAuthenticationResponse): void
}

const useProcessAuthenticationResponse: UseProcessAuthenticationResponse =
  result => {
    const setPhase = useSetRecoilState(aPhase)
    const setLoginStep = useSetRecoilState(aLoginStep)
    const [, handleLoginError] = useRecoilState(sHandleLoginError)
    const loginPhaseError = useSetRecoilState(aLoginPhaseError)
    const setBearerToken = useSetRecoilState(aBearerToken)
    const setRsaPublicKey = useSetRecoilState(aRsaPublicKey)
    const setSwtOnlineDeviceName = useSetRecoilState(aSwtOnlineDeviceName)
    const setSwtOfflineFollowUpData = useSetRecoilState(aSwtOfflineFollowUpData)
    const setSwtOnlineValidityInSeconds = useSetRecoilState(
      aSwtOnlineValidityInSeconds
    )

    useEffect(() => {
      if (!result) {
        return
      }

      const {
        authorizationStatus,
        authToken,
        swtOnlineDeviceName,
        swtOnlineValidityInSeconds,
        swtOfflineFollowUpData,
        rsaPublicKey,
        ibUrl,
        authorizationAttemptResult,
      } = result

      if (
        authorizationStatus === "REJECTED" &&
        authorizationAttemptResult === "NOK_LOGOUT"
      ) {
        loginPhaseError("REJECTED_LOGOUT")
        return
      }

      if (
        authorizationStatus === "NO_AUTHORIZATION_METHOD" ||
        authorizationStatus === "NIA_NOT_IDENTIFIED" ||
        authorizationStatus === "NIA_OPTED_OUT" ||
        authorizationStatus === "TIMEOUTED" ||
        (authorizationStatus === "REJECTED" &&
          authorizationAttemptResult === "NOK_BLOCK")
      ) {
        handleLoginError({
          loginPhaseError:
            authorizationStatus === "REJECTED"
              ? "REJECTED_BLOCK"
              : authorizationStatus,
          ibUrl,
        })
        return
      }

      if (authorizationStatus === "AUTHORIZED") {
        setPhase("authorized")
        return
      }

      setLoginStep(result)

      if (authToken) {
        setBearerToken(authToken)
      }

      if (rsaPublicKey) {
        setRsaPublicKey(rsaPublicKey)
      }

      if (swtOnlineDeviceName) {
        setSwtOnlineDeviceName(swtOnlineDeviceName)
      }

      if (swtOfflineFollowUpData) {
        setSwtOfflineFollowUpData(swtOfflineFollowUpData)
      }

      if (swtOnlineValidityInSeconds) {
        setSwtOnlineValidityInSeconds(swtOnlineValidityInSeconds)
      }
    }, [
      loginPhaseError,
      handleLoginError,
      result,
      setBearerToken,
      setLoginStep,
      setPhase,
      setRsaPublicKey,
      setSwtOfflineFollowUpData,
      setSwtOnlineDeviceName,
      setSwtOnlineValidityInSeconds,
    ])
  }

export default useProcessAuthenticationResponse
