import React, { FC } from "react"

const Rejected: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Přístup jsme vám zablokovali
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Z&nbsp;bezpečnostních důvodů jsme vám zablokovali některý z&nbsp;vašich
      bezpečnostních prvků.
    </p>
    <p className="mt-4 text-sm">
      Nejčastější příčinou blokace bývá opakované chybné zadání hesla. Pokud je
      to&nbsp;tento případ, odblokování bude hračka. Stačí si heslo obnovit
      a&nbsp;pak&nbsp;se&nbsp;s&nbsp;ním přihlásit do&nbsp;internetového
      bankovnictví.
    </p>
    <p className="mt-4 text-sm">
      Na&nbsp;přihlašovací obrazovce internetového bankovnictví klikněte&nbsp;na
      Nepamatuji si heslo a&nbsp;my&nbsp;vás navedeme.
    </p>
  </>
)

export default Rejected
