import axios, { AxiosRequestConfig } from "axios"

import { CancellableRequest } from "./types"
import { BearerToken, ServiceProvider } from "../../state/global"

type GetQrTokenRequest = CancellableRequest & {
  appId: ServiceProvider
  sessionId: string
  bearerToken: NonNullable<BearerToken>
}

type GetQrTokenResponse = {
  authToken: NonNullable<BearerToken>
  qrContent: string
}

interface ExecGetQrToken {
  (data: GetQrTokenRequest): Promise<GetQrTokenResponse>
}

/**
 * Note: extend typing by adding more allowed generics,
 * like ExecPreLogin<NiaPreLogin | BankIdPreLogin>
 */
const execGetQrToken: ExecGetQrToken = async data => {
  const { appId, sessionId, bearerToken, cancelToken } = data

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  // TODO handle 400 errors?
  const response = await axios.post<GetQrTokenResponse>(
    `/api/apa/v1/authentication/getQrToken?${sessionId}`,
    {
      appId,
    },
    config
  )

  const { authToken, qrContent } = response.data

  return { authToken, qrContent }
}

export default execGetQrToken
