import React, { FC } from "react"

const Birthday: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Datum narození po vás nechceme jen tak
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Abychom měli jistotu, že se chcete přihlásit opravdu vy, a ne někdo jiný,
      občas vás požádáme o&nbsp;vyplnění správného data narození. Tak jako
      právě&nbsp;teď.
    </p>
    <p className="text-sm mt-6">
      Datum prosím zadávejte ve formátu <strong>DD. MM. RRRR</strong>.
    </p>
  </>
)

export default Birthday
