import React, { FC } from "react"

const NiaNotIdentified: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Bankovní identitu zatím využívat nemůžete
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="text-sm">
      Službu poskytujeme pouze přímým vlastníkům účtu, tedy těm, na&nbsp;jejichž
      jméno účet u&nbsp;nás vedeme, se&nbsp;kterými jsme&nbsp;se alespoň jednou
      viděli tváří v&nbsp;tvář a&nbsp;od&nbsp;kterých máme aktuální doklad
      totožnosti vydaný úřadem v&nbsp;České republice.
    </p>
    <p className="mt-4 text-sm">
      Jestli některou z&nbsp;těchto podmínek nesplňujete, stavte&nbsp;se
      na&nbsp;kterékoliv naší pobočce a&nbsp;společně&nbsp;se to pokusíme
      vyřešit. Určitě&nbsp;si ale s&nbsp;sebou nezapomeňte vzít doklad
      totožnosti.
    </p>
  </>
)

export default NiaNotIdentified
