import axios, { AxiosResponse } from "axios"

import { FilledBearerToken } from "../../state/global"

type LopiRequest = {
  authId: string
  bearerToken: FilledBearerToken
  timeout: number
}

export type LopiResponse = AxiosResponse<Record<string, never>>

interface ExecLopiSwtOnline {
  (data: LopiRequest): Promise<LopiResponse>
}

const execLopiSwtOnline: ExecLopiSwtOnline = data => {
  const { authId, bearerToken, timeout } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
  }

  return axios.get(
    `/api/lopi/auth/getState?loginToken=${authId}&timeout=${timeout}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )
}

export default execLopiSwtOnline
