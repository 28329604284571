import { useCallback, useState } from "react"
import { useBoolean, useInterval } from "react-use"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"
import { decode } from "jsonwebtoken"
import {
  aBearerToken,
  aDontLogRequestError,
  FilledBearerToken,
} from "../state/global"
import { execLoginStatus, execLopiSwtOnline } from "../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "./use-process-authentication-response"
import { aSwtOnlineValidityInSeconds } from "../state/swt"
import { StatusRequest } from "../requests/authentication/status"
import { sHandleLoginError } from "../state/management"
import isSafariNetworkError from "../utils/safari-network-error"

const useHandleSwtOnline = (): boolean => {
  const handleError = useErrorHandler()
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const [, handleLoginError] = useRecoilState(sHandleLoginError)
  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()
  const [timeLeft, setTimeLeft] = useState(
    useRecoilValue(aSwtOnlineValidityInSeconds) as number
  )
  const [loginStatusCancel] = useState(axios.CancelToken.source())
  const [isFinished, setIsFinished] = useBoolean(false)
  const [isRunning, setIsRunning] = useBoolean(true)
  const setDontLogRequestError = useSetRecoilState(aDontLogRequestError)

  // we need to pharse authId because lopi is just a notifier (dumb), security is not compromised by this
  const authId = decode(bearerToken, {
    complete: true,
    json: true,
  })?.payload.additionalData?.authId

  useProcessAuthenticationResponse(fetchResult)

  const handleLoginStatus = useCallback(
    async (data: StatusRequest) => {
      try {
        const attemptResult = await execLoginStatus(data)
        if (attemptResult?.authorizationStatus === "REJECTED") {
          handleLoginError({ loginPhaseError: "REJECTED_SWT_ONLINE" })
          return 0
        }
        setFetchResult(attemptResult)
        return attemptResult?.swtOnlineValidityInSeconds
      } catch (error) {
        const isNetworkError = isSafariNetworkError(error)
        if (isNetworkError) {
          return 1
        }

        if (axios.isCancel(error)) {
          return 0
        }

        handleError(error)
        return 0
      }
    },
    [handleError, handleLoginError]
  )

  const callLopi = useCallback(async () => {
    setIsRunning(false)
    if (timeLeft === undefined || timeLeft <= 0) {
      loginStatusCancel.cancel()
      setIsFinished(true)
      return
    }

    try {
      await execLopiSwtOnline({ authId, bearerToken, timeout: timeLeft })
    } catch (error) {
      const isNetworkError = isSafariNetworkError(error)

      // handle 400, 404... from lopi
      if (
        axios.isAxiosError(error) &&
        error.response?.status !== 408 &&
        error.response?.status !== 504 &&
        !isNetworkError &&
        !axios.isCancel(error)
      ) {
        setDontLogRequestError(false)
        handleError(error)
        return
      }

      if (!isNetworkError) {
        await handleLoginStatus({
          bearerToken,
          sessionId: authId,
          cancelToken: loginStatusCancel.token,
        })
        setIsFinished(true)
        return
      }
    }

    const newTimeLeft = await handleLoginStatus({
      bearerToken,
      sessionId: authId,
      cancelToken: loginStatusCancel.token,
    })
    setTimeLeft(newTimeLeft ?? 0)
    setIsRunning(true)
  }, [
    timeLeft,
    authId,
    bearerToken,
    loginStatusCancel,
    setIsRunning,
    setIsFinished,
    handleError,
    setDontLogRequestError,
    handleLoginStatus,
  ])

  useInterval(callLopi, isRunning ? 0 : null)

  return isFinished
}
export default useHandleSwtOnline
