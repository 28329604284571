import React, { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"
import QRCode from "qrcode.react"

import { aBearerToken, aLoading, FilledBearerToken } from "../../state/global"
import { aSwtOfflineFollowUpData } from "../../state/swt"
import {
  aApaSessionId,
  aRsaPublicKey,
  RSAPublicKey,
} from "../../state/auxiliary"
import { execLoginAttempt } from "../../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "../../hooks/use-process-authentication-response"
import { encryptPassword } from "../../utils/password-crypto"

import Button from "../ui/button"
import TextField from "../ui/text-field/index"

interface Form {
  PWD: string
  SWT_OFFLINE: string
}

const SWTOffline: FC = () => {
  const { control, handleSubmit } = useForm<Form>({
    mode: "onSubmit",
  })
  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const swtOffline = useRecoilValue(aSwtOfflineFollowUpData)
  const rsaPublicKey = useRecoilValue(
    aRsaPublicKey
  ) as NonNullable<RSAPublicKey>

  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()
  useProcessAuthenticationResponse(fetchResult)

  const onSubmit: SubmitHandler<Form> = async data => {
    setLoading(true)

    const modifiedData = data

    if (rsaPublicKey) {
      modifiedData.PWD = await encryptPassword(data.PWD, rsaPublicKey)
    }

    try {
      const attemptResult = await execLoginAttempt({
        sessionId,
        bearerToken,
        ...modifiedData,
      })

      setFetchResult(attemptResult)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  if (!swtOffline) {
    return null
  }

  return (
    <>
      <p className="text-xs sm:mt-8 mb-4">
        Načtěte QR kód pomocí spárované mobilní&nbsp;aplikace.
      </p>
      <div className="flex justify-center mb-4 p-5 bg-white rounded-lg">
        <QRCode value={swtOffline} size={200} renderAs="svg" />
      </div>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          control={control}
          name="SWT_OFFLINE"
          label="Kód z mobilní aplikace"
          defaultValue=""
          rules={{
            required: "Toto pole je povinné",
          }}
          type="password"
          autoFocus
          autoComplete="off"
          inputTestId="input-code-from-mobile"
        />

        <TextField
          control={control}
          name="PWD"
          label="Heslo"
          defaultValue=""
          rules={{
            required: "Toto pole je povinné",
          }}
          type="password"
          autoComplete="off"
          hasVirtualKeyboard
          inputTestId="input-password"
        />

        <Button
          testId="button-continue"
          type="submit"
          className="w-full mt-6"
          disabled={isLoading}
        >
          Pokračovat
        </Button>
      </form>
    </>
  )
}

export default SWTOffline
