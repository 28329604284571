import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import { aBearerToken, aPhase, FilledBearerToken } from "../state/global"
import { aApaSessionId } from "../state/auxiliary"
import { sFinishAuthentication } from "../state/management"
import { execLoginFinish } from "../requests/authentication"

const useHandleAuthenticationFinish = (): void => {
  const handleError = useErrorHandler()
  const phase = useRecoilValue(aPhase)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const [, finishAuthentication] = useRecoilState(sFinishAuthentication)

  useEffect(() => {
    const handleAuthenticationFinish = async () => {
      try {
        const { authToken, redirectUrl } = await execLoginFinish({
          sessionId,
          bearerToken,
        })

        finishAuthentication({
          bearerToken: authToken,
          phase: "postLogin",
          redirectUrl,
        })
      } catch (error) {
        if (axios.isCancel(error)) {
          return
        }

        handleError(error)
      }
    }

    if (phase !== "authorized") {
      return
    }

    handleAuthenticationFinish()
  }, [bearerToken, finishAuthentication, handleError, phase, sessionId])
}

export default useHandleAuthenticationFinish
