import React, { FC } from "react"
import { useRecoilValue } from "recoil"

import {
  aOperationType,
  aPhase,
  sIsServiceProviderBankId,
  sIsServiceProviderNIA,
} from "../../state/global"
import { aLoginPhaseError, aLoginStep } from "../../state/login-step"

import BankId from "./bank-id"
import BankSign from "./bank-sign"
import Birthday from "./birthday"
import LastAttempt from "./last-attempt"
import NiaDefault from "./nia/default"
import NiaNotIdentified from "./nia/not-identified"
import NiaOptedOut from "./nia/opted-out"
import NoAuthorizationMethod from "./no-authorization-method"
import Password from "./password"
import PwdAndSms from "./pwd-and-sms"
import Rejected from "./rejected"
import SMS from "./sms"
import SWTOffline from "./swt-offline"
import SWTOnline from "./swt-online"
import TryAgain from "./try-again"
import TokenInvalid from "./token-invalid"
import AuthorizationFail from "./ authorization-fail"
import UrlExpired from "./url-expired"

import styles from "./index.module.css"

interface Props {
  className?: string
}

const Wrapper: FC<Props> = ({ children, className = "" }) => {
  return (
    <aside
      className={`${className} flex flex-col p-4 sm:py-10 sm:pl-10 sm:pr-5`}
    >
      {children}
    </aside>
  )
}

const Aside: FC<Props> = ({ className = "" }) => {
  const isServiceProviderNIA = useRecoilValue(sIsServiceProviderNIA)
  const isServiceProviderBankId = useRecoilValue(sIsServiceProviderBankId)
  const phase = useRecoilValue(aPhase)
  const error = useRecoilValue(aLoginPhaseError)
  const operationType = useRecoilValue(aOperationType)
  const { authorizationAttemptResult, nextAuthorizationStep } =
    useRecoilValue(aLoginStep)

  if (phase === "error") {
    return (
      <Wrapper className={className}>
        {error === "NO_AUTHORIZATION_METHOD" && <NoAuthorizationMethod />}
        {error === "NIA_NOT_IDENTIFIED" && <NiaNotIdentified />}
        {error === "NIA_OPTED_OUT" && <NiaOptedOut />}
        {(error === "REJECTED_BLOCK" || error === "QR_REJECTED") && (
          <Rejected />
        )}
        {error === "TOKEN_INVALID" && <TokenInvalid />}
        {error === "REJECTED_SWT_ONLINE" && <AuthorizationFail />}
        {error === "REDIRECT_URL_EXPIRED" && <UrlExpired />}
      </Wrapper>
    )
  }

  if (
    phase === "login" &&
    (authorizationAttemptResult === "NOK_TRY_AGAIN" ||
      (authorizationAttemptResult === "PROCEED" &&
        nextAuthorizationStep === "BIRTHDAY"))
  ) {
    return (
      <Wrapper className={className}>
        <TryAgain nextAuthorizationStep={nextAuthorizationStep} />
      </Wrapper>
    )
  }

  if (phase === "login" && authorizationAttemptResult === "NOK_LAST_ATTEMPT") {
    return (
      <Wrapper className={className}>
        <LastAttempt nextAuthorizationStep={nextAuthorizationStep} />
      </Wrapper>
    )
  }

  if (
    phase === "login" &&
    (nextAuthorizationStep === "PWD" ||
      nextAuthorizationStep === "PWD_AND_SWT_OFFLINE")
  ) {
    return (
      <Wrapper className={className}>
        <Password />
      </Wrapper>
    )
  }

  if (phase === "login" && nextAuthorizationStep === "PWD_AND_SMS") {
    return (
      <Wrapper className={className}>
        <PwdAndSms />
      </Wrapper>
    )
  }

  if (phase === "login" && nextAuthorizationStep === "BIRTHDAY") {
    return (
      <Wrapper className={className}>
        <Birthday />
      </Wrapper>
    )
  }

  if (phase === "login" && nextAuthorizationStep === "SMS") {
    return (
      <Wrapper className={className}>
        <SMS />
      </Wrapper>
    )
  }

  if (phase === "login" && /^SWT_ONLINE.*/.test(nextAuthorizationStep || "")) {
    return (
      <Wrapper className={className}>
        <SWTOnline />
      </Wrapper>
    )
  }

  if (phase === "login" && nextAuthorizationStep === "SWT_OFFLINE") {
    return (
      <Wrapper className={className}>
        <SWTOffline />
      </Wrapper>
    )
  }

  /**
   * Display default information for NIA service provider
   */

  if (isServiceProviderNIA) {
    // service provider for sign can be only bankId
    return (
      <Wrapper className={className}>
        <NiaDefault />
      </Wrapper>
    )
  }

  if (isServiceProviderBankId) {
    return operationType === "DOCUMENT_SIGN" ? (
      <Wrapper className={`${styles.wrapperSign} ${className}`}>
        <BankSign />
      </Wrapper>
    ) : (
      <Wrapper className={className}>
        <BankId />
      </Wrapper>
    )
  }

  return null
}

export default Aside
