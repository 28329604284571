import axios from "axios"

import { FilledBearerToken } from "../state/global"

/**
 * ERROR LOG
 */

type LogEventLevel = "ERROR" | "WARN" | "INFO"

export type ErrorLogRequestBody = {
  feSessionId: string
  message: string
  stackTrace: string
  bearerToken: FilledBearerToken
  level: LogEventLevel
}

interface ExecErrorLog<T> {
  (data: T): Promise<number>
}

export const execErrorLog: ExecErrorLog<ErrorLogRequestBody> = async data => {
  const { feSessionId, message, stackTrace, bearerToken, level } = data

  const response = await axios.post<number>(
    `/api/apa/v1/public/logFeEvent?${feSessionId}`,
    { feSessionId, message, stackTrace, level },
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )

  return response.status
}
