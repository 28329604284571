import React, { FC, useEffect } from "react"
import { useRecoilValue, useResetRecoilState } from "recoil"

import { aPhase, aUsername } from "../../state/global"
import { aLoginPhaseError, aLoginStep } from "../../state/login-step"
import { ReactComponent as Logo } from "../../assets/logo.svg"
import { aSwtOfflineFollowUpData } from "../../state/swt"

const Header: FC = () => {
  const username = useRecoilValue(aUsername)
  const error = useRecoilValue(aLoginPhaseError)
  const loginStep = useRecoilValue(aLoginStep)
  const phase = useRecoilValue(aPhase)
  const resetLoginStep = useResetRecoilState(aLoginStep)
  const resetUsername = useResetRecoilState(aUsername)
  const resetSwtOfflineFollowUpData = useResetRecoilState(
    aSwtOfflineFollowUpData
  )

  useEffect(() => {
    if (
      phase !== "login" ||
      error ||
      loginStep.nextAuthorizationStep === "USERNAME"
    ) {
      resetUsername()
    }
  }, [phase, error, loginStep, resetUsername])

  return (
    <>
      <header className="h-20 grid grid-cols-3 text-sm">
        <div className=" col-start-1 col-span-1">
          <Logo className="sm:ml-3 ml-0 sm:w-auto w-32" />
        </div>
        {username && (
          <div
            className="col-start-2 col-span-2 grid grid-rows-2 sm:mr-6 mr-3 pb-5 sm:pt-0 pt-2 text-right sm:text-base text-sm place-self-end"
            data-testid="username"
          >
            <div className="row-start-1 row-span-1 overflow-hidden sm:overflow-visible self-end pl-6 sm:pl-0 leading-loose sm:leading-none">
              <strong>{username}</strong>
            </div>
            <div className="row-start-2 row-span-1">
              <button
                data-testid="button-wrong-username"
                className="inline-block pl-5 underline hover:no-underline bg-no-repeat text-xs leading-none"
                onClick={() => {
                  resetLoginStep()
                  resetUsername()
                  resetSwtOfflineFollowUpData()
                }}
              >
                Nejste to vy?
              </button>
            </div>
          </div>
        )}
      </header>
    </>
  )
}

export default Header
