import { FC, useState } from "react"
import { useRecoilValue } from "recoil"
import execRejectAuthorization from "../../requests/reject-authorization"
import { aBearerToken, FilledBearerToken } from "../../state/global"
import RedirectForm from "../redirect-form"

import Button from "../ui/button"
import Modal from "../ui/modal"

type SignCancelationModalProps = {
  callbackModal?: () => void
}

const SignCancelationModal: FC<SignCancelationModalProps> = ({
  callbackModal,
}) => {
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const [submitCancelation, setSubmitCancelation] = useState(false)

  const onRejectAuth = async () => {
    try {
      await execRejectAuthorization({
        bearerToken,
        rejectionReason: "CONSENTS_REJECTED",
      })
    } catch (err) {
      // cease logging the event if error occured while logging it
    } finally {
      setSubmitCancelation(true)
    }
  }

  return (
    <>
      <Modal title="Upozornění" onClose={callbackModal}>
        <strong>
          Opravdu chcete vše zrušit? Bez předání uvedených údajů nelze
          podepisování dokončit.
        </strong>
        <div className="py-1 grid grid-cols-2">
          <div>
            <Button variant="white" onClick={callbackModal}>
              ne
            </Button>
          </div>
          <div className="flex justify-self-end">
            <Button variant="green" type="button" onClick={onRejectAuth}>
              Ano
            </Button>
            <RedirectForm autoSubmit={submitCancelation} action="CANCEL" />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SignCancelationModal
