import { atom } from "recoil"

export const aSwtOnlineDeviceName = atom<string | undefined>({
  key: "aSwtOnlineDeviceName",
  default: undefined,
})

export const aSwtOnlineValidityInSeconds = atom<number | undefined>({
  key: "aSwtOnlineValidityInSeconds",
  default: undefined,
})

export const aSwtOfflineFollowUpData = atom<string | undefined>({
  key: "aSwtOfflineFollowUpData",
  default: undefined,
})
