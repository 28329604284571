import React, { FC } from "react"

import { LoginStep } from "../../../state/login-step"

import Content from "./content"

type Props = {
  nextAuthorizationStep: LoginStep["nextAuthorizationStep"]
}

const TryAgain: FC<Props> = ({ nextAuthorizationStep }) => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">Nepoznáváme vás</h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <Content nextAuthorizationStep={nextAuthorizationStep} />
    </>
  )
}

export default TryAgain
