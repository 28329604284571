import React, { FC } from "react"
import { useRecoilValue } from "recoil"
import { Redirect } from "react-router-dom"
import classNames from "classnames"

import { aPhase } from "../../state/global"
import { aLoginStep } from "../../state/login-step"
import sShouldShowQR from "../../state/qr"

import useBootApp from "../../hooks/use-boot-app"
import useHandlePrelogin from "../../hooks/use-handle-prelogin"
import useHandleAuthenticationFinish from "../../hooks/use-handle-authentication-finish"
import useHandlePostLogin from "../../hooks/use-handle-postlogin"

import styles from "./index.module.css"

import Aside from "../../components/aside"
import LoginError from "../../components/login-form/login-error"
import Footer from "../../components/footer/footer"
import LoginForm from "../../components/login-form"
import QR from "../../components/login-form/qr"
import RedirectForm from "../../components/redirect-form"

import Header from "../../components/header/header"
import TimeoutModal from "../../components/modals/timeout-modal"
import SemiLockedModal from "../../components/modals/semi-locked-modal"
import CancelLoginBanner from "../../components/banners/cancel-login-banner"

const Login: FC = () => {
  const phase = useRecoilValue(aPhase)
  const { authorizationAttemptResult } = useRecoilValue(aLoginStep)
  const shouldShowQR = useRecoilValue(sShouldShowQR)

  /**
   * useBootApp handles initial hash parsing and state setup
   */
  useBootApp()

  /**
   * useHandlePrelogin handles preLogin phase
   * - default for NiaApp
   * - skipped for IB
   */
  useHandlePrelogin()

  /**
   * useHandleAuthenticationFinish handles the end of login phase
   * - user is authenticated
   * - after finishing authentication, we move to postLogin
   */
  useHandleAuthenticationFinish()

  /**
   * useHandlePostLogin handles postLogin phase
   * - either redirect
   * - or consent approval
   */
  useHandlePostLogin()

  const mainCss = classNames(
    styles.form,
    "sm:absolute sm:top-0 sm:right-0 sm:bottom-0 sm:-mt-4 sm:-mb-5 sm:mr-5 sm:ml-0 -m-px p-5 flex flex-col rounded-md",
    {
      "bg-green-brand":
        phase === "login" && ["OK", null].includes(authorizationAttemptResult),
      "bg-black-fa border border-solid border-black-b0":
        phase === "error" ||
        (phase === "login" &&
          ["NOK_LAST_ATTEMPT", "NOK_TRY_AGAIN", "PROCEED"].includes(
            authorizationAttemptResult || ""
          )),
    }
  )

  if (phase === "redirect") {
    return <RedirectForm action="OK" />
  }

  if (phase === "consents") {
    return <Redirect to="/consents" />
  }

  return (
    <>
      <section className="mx-auto max-w-5xl px-6 mt-10 sm:mt-16">
        <Header />
        <div
          className={`${styles.wrapper} flex flex-col sm:block relative bg-white border border-solid border-black-da rounded-md`}
        >
          <Aside />
          <div className={mainCss}>
            {phase === "login" && (
              <>
                <LoginForm />
              </>
            )}
            {phase === "error" && <LoginError />}
            {shouldShowQR && <QR />}
          </div>
        </div>
        <Footer />
        <TimeoutModal />
        <SemiLockedModal />
      </section>
      <CancelLoginBanner />
    </>
  )
}

export default Login
