import { ServiceProvider } from "../state/global"
import { IdPortData } from "../state/idport"

type Hash = {
  app_id: ServiceProvider
}

// TODO try to set usage to string only
export type IdPortHash = Hash & IdPortData

const parseHash = (hash: string): IdPortHash | null => {
  if (hash.length <= 0) {
    return null
  }

  const parts = hash.substring(1).split("&")
  const pairs = parts.map(part => part.split("="))

  const objArray = pairs.map(([key, val]) => ({
    [key]: decodeURIComponent(val),
  }))

  return Object.assign({}, ...objArray)
}

export default parseHash
