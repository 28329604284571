import axios from "axios"

import { IdPortRedirect } from "./types"
import { FilledBearerToken } from "../state/global"
import { Consents } from "../state/idport"

type PostLoginResponse = {
  redirect: IdPortRedirect | null
  consents: Consents | null
}

export type PostLoginRequest = {
  apaSessionId: string
  bearerToken: FilledBearerToken
}

interface ExecPostLogin<T> {
  (data: T): Promise<PostLoginResponse>
}

export const execPostLogin: ExecPostLogin<PostLoginRequest> = async data => {
  const { apaSessionId, bearerToken } = data

  // TODO handle 400 errors?
  const response = await axios.post<PostLoginResponse>(
    `/api/apa/v1/bankid/postLogin?${apaSessionId}`,
    {},
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )

  const { consents, redirect } = response.data

  return { consents, redirect }
}
