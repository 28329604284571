import React, { createRef, FC, useState } from "react"
import { Control, UseFormRegister } from "react-hook-form"
import { upperCaseFirst } from "upper-case-first"
import format from "date-fns/format"
import classNames from "classnames"

import { useClickAway } from "react-use"

import { ReactComponent as Info } from "../../assets/infoBorderGreen.svg"
import { ProfileScopes, Scopes } from "../../state/idport"
import Checkbox from "../ui/checkbox"
import InfoTooltip from "../ui/info-tooltip"

// we need to parse yyyy-MM-dd and pass it to Date constructor separately
// to avoid invalid date conversion caused by TimeZone
// see https://stackoverflow.com/questions/35205627/why-a-given-date-turns-into-a-day-before-when-using-new-date
const dateFormatter = (dateToFormat: string): string => {
  const values = dateToFormat.split("-").map(item => parseInt(item, 10))
  if (values.length > 0) {
    const year = values[0]
    const month = values[1] - 1
    const date = values[2]
    return format(new Date(year, month, date), "dd. MM. yyyy")
  }
  return ""
}

export type FormData = {
  profile: Partial<Record<ProfileScopes, string>>
  notification?: {
    claims_updated: string
  }
  offline_access: string
}

type Props = {
  data: unknown
  scope: Scopes
  isRequired?: boolean
  control?: Control<FormData>
  register?: UseFormRegister<FormData>
}

type MaritalStatus =
  | "COHABITATION"
  | "MARRIED"
  | "DIVORCED"
  | "REGISTERED_PARTNERSHIP"
  | "REGISTERED_PARTNERSHIP_CANCELED"
  | "WIDOWED"
  | "SINGLE"
  | "UNKNOWN"
  | string

const FormattedConsent: FC<Props> = ({
  data,
  scope,
  isRequired = true,
  control,
  register,
}) => {
  // info modal implementation
  const [infoHidden, hideInfo] = useState(true)
  const infoTooltipRef = createRef<HTMLDivElement>()
  useClickAway(infoTooltipRef, () => hideInfo(true))

  const checkboxCss = classNames(
    "flex col-start-1 col-span-1 pr-3 space-x-4 items-center"
  )
  const checkboxTextCss = classNames("w-full text-left")
  const labelCss = classNames(
    "col-start-1 col-span-1 row-span-1 text-left pr-3 pl-8"
  )
  const optionalValue = classNames(
    control ? "mb-2 ml-8 sm:m-0" : null,
    "col-start-2 col-span-2 row-span-1 font-bold"
  )
  const requiredValue = classNames(
    "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0"
  )

  if (scope === "notification.claims_updated") {
    return (
      <>
        {register && (
          <input
            data-testid={`input-${scope}`}
            {...register(scope)}
            name={scope}
            type="hidden"
            key={scope}
            value={scope}
          />
        )}
      </>
    )
  }

  if (scope === "offline_access") {
    const infoContent = isRequired
      ? "Společnost vyžaduje, abychom ji informovali, kdykoliv se dozvíme o změně některého z údajů, s jejichž předáním jste souhlasili. Nebudete si tak muset hlídat na více místech, zda jsou vaše údaje stále aktuální. Souhlas můžete kdykoliv odvolat v Internetovém bankovnictví v sekci Zabezpečení."
      : "Kdykoliv se dozvíme o změně některého z údajů, s jejichž předáním jste souhlasili, můžeme o tom danou společnost informovat. Nebudete si tak muset hlídat na více místech, zda jsou vaše údaje stále aktuální. Souhlas můžete kdykoliv odvolat v Internetovém bankovnictví v sekci Zabezpečení."

    return (
      <>
        {!infoHidden ? (
          <InfoTooltip
            ref={infoTooltipRef}
            variant="info"
            infoText={infoContent}
            className="-mt-16 sm:-mt-12"
          />
        ) : null}
        <hr className="my-6 border-t border-solid border-black-da" />
        <div className="flex justify-center mb-8 ml-4 text-xs">
          {isRequired && register && (
            <>
              <div className="font-bold">
                Potvrzením souhlasíte s průběžnou aktualizací údajů
              </div>
              <input
                data-testid={`input-${scope}`}
                {...register(scope)}
                name={scope}
                type="hidden"
                key={scope}
                value={scope}
              />
            </>
          )}
          {!isRequired && control && (
            <Checkbox
              control={control}
              name={scope}
              value={scope}
              defaultValue="offline_access"
              label="Souhlasím s průběžnou aktualizací údajů"
              className={
                isRequired
                  ? "col-span-2 flex space-x-3 font-bold"
                  : "col-span-2 flex space-x-3"
              }
            />
          )}
          <Info
            className="ml-2 cursor-pointer"
            onClick={() => hideInfo(!hideInfo)}
          />
        </div>
      </>
    )
  }

  // hidden scopes that will be send regardless of isRequired
  if (
    scope === "profile.updatedat" ||
    scope === "profile.locale" ||
    scope === "profile.zoneinfo"
  ) {
    return (
      <>
        {register && (
          <input
            data-testid={`input-${scope}`}
            {...register(scope)}
            name={scope}
            type="hidden"
            key={scope}
            value={scope}
          />
        )}
      </>
    )
  }

  if (scope === "profile.addresses") {
    const value = data as Record<string, string>
    const permanent = value?.PERMANENT ? value.PERMANENT : ""
    const reports = value?.REPORTS ? value.REPORTS : ""

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Adresa bydliště</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              name={scope}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Adresa bydliště"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={requiredValue}>{permanent}</div>
        <div className={`${labelCss} row-start-2`}>Kontaktní adresa</div>
        <div className={`${requiredValue} row-start-2`}>{reports}</div>
      </>
    )
  }

  if (scope === "profile.birthdate") {
    const value = data as string
    let birthdate = ""
    // just for ie
    try {
      birthdate = dateFormatter(value)
    } catch {
      birthdate = ""
    }

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Datum narození</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              name={scope}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Datum narození"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{birthdate}</div>
      </>
    )
  }

  if (scope === "profile.birthnumber") {
    const value = data as string

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Rodné číslo</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Rodné číslo"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{value}</div>
      </>
    )
  }

  if (scope === "profile.birthplaceNationality") {
    const value = data as Record<string, string>

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Místo narození</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Místo narození"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={`${optionalValue} row-start-1`}>
          {value?.placeToDisplay}
        </div>
        <div className={`${optionalValue} row-start-2`}>
          {value?.stateToDisplay}
        </div>
      </>
    )
  }

  if (scope === "profile.email") {
    const value = data as string

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>E-mail</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="E-mail"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{value}</div>
      </>
    )
  }

  if (scope === "profile.gender") {
    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Pohlaví</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Pohlaví"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{data === "male" ? "muž" : "žena"}</div>
      </>
    )
  }

  if (scope === "profile.maritalstatus") {
    const value = data as string

    const parseMaritalStatus = (status: MaritalStatus) => {
      let result = "neznámý"
      switch (status) {
        case "COHABITATION":
          result = "společná domácnost"
          break
        case "MARRIED":
          result = "ženatý/vdaná"
          break
        case "DIVORCED":
          result = "rozvedený/á"
          break
        case "REGISTERED_PARTNERSHIP":
          result = "v registrovaném parnerství"
          break
        case "REGISTERED_PARTNERSHIP_CANCELED":
          result = "ukončené registrované partnerství"
          break
        case "WIDOWED":
          result = "ovdovělý/á"
          break
        case "SINGLE":
          result = "svobodný/á"
          break
        case "UNKNOWN":
          result = "neznámý"
          break
        default:
          result = "neznámý"
      }
      return result
    }

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Rodinný stav</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Rodinný stav"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{parseMaritalStatus(value)}</div>
      </>
    )
  }

  if (scope === "profile.titles") {
    const value = data as string

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Tituly</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Tituly"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{value}</div>
      </>
    )
  }

  if (scope === "profile.name") {
    const value = data as Record<string, string> & string
    const givenName = value?.given_name
      ? upperCaseFirst(value.given_name.toLowerCase())
      : value.split("_")[0]
    const familyName = value?.family_name
      ? upperCaseFirst(value.family_name.toLowerCase())
      : value.split("_")[1]

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Jméno</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Jméno"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={requiredValue}>{givenName}</div>
        <div className={`${labelCss} row-start-2`}>Příjmení</div>
        <div className={`${requiredValue} row-start-2`}>{familyName}</div>
      </>
    )
  }

  if (scope === "profile.paymentAccounts") {
    const value = data as Array<string>
    const isValidArray = value.length > 0 && value[0].length > 1
    const accountsClasses = [
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-2",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-3",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-4",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-5",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-6",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-7",
      "col-start-2 col-span-2 row-span-1 font-bold mb-2 ml-8 sm:m-0 row-start-8",
    ]

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Bankovní účty</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Bankovní účty"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        {isValidArray
          ? value.map((accNum, idx) => (
              <div key={accNum} className={accountsClasses[idx]}>
                {accNum}
              </div>
            ))
          : null}
      </>
    )
  }

  if (scope === "profile.idcards") {
    const value = data as Record<string, string>
    let issueDate
    // just for ie
    try {
      issueDate = value?.issue_date ? dateFormatter(value.issue_date) : ""
    } catch {
      issueDate = ""
    }

    let validTo
    // just for ie
    try {
      validTo = value?.["idcards.valid_to"]
        ? dateFormatter(value["idcards.valid_to"])
        : ""
    } catch {
      validTo = ""
    }

    const parseIdType = (card: string) => {
      let result = "neznámý"
      switch (card) {
        case "ID":
          result = "občanský průkaz"
          break
        case "ID_CARD":
          result = "občanský průkaz"
          break
        case "IDCARD":
          result = "občanský průkaz"
          break
        case "P":
          result = "cestovní pas"
          break
        case "DL":
          result = "řidičský průkaz"
          break
        case "IR":
          result = "povolení k pobytu"
          break
        case "VS":
          result = "vízum"
          break
        case "PS":
          result = "povolení k pobytu"
          break
        default:
          result = "neznámý"
      }
      return result
    }

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Typ primárního dokladu</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Typ primárního dokladu"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={requiredValue}>
          {parseIdType(value?.["idcards.type"])}
        </div>
        <div className={`${labelCss} row-start-2`}>
          Číslo primárního dokladu
        </div>
        <div className={`${requiredValue} row-start-2`}>
          {value?.["idcards.number"]}
        </div>
        <div className={`${labelCss} row-start-3`}>Datum vydání dokladu</div>
        <div className={`${requiredValue} row-start-3`}>{issueDate}</div>
        <div className={`${labelCss} row-start-4`}>Platnost dokladu</div>
        <div className={`${requiredValue} row-start-4`}>{validTo}</div>
        <div className={`${labelCss} row-start-5`}>Vydavatel dokladu</div>
        <div className={`${requiredValue} row-start-5`}>{value?.issuer}</div>
      </>
    )
  }

  if (scope === "profile.legalstatus") {
    const value = data as Record<string, boolean | string>
    const majority = !!(value?.majority === true)

    // TO BE USED later
    // const limitedLegalCapacity = (value?.limited_legal_capacity === true)
    // ? true
    // : false

    const isPEP = !!(value?.pep === true)

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Právní status</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Právní status"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        {majority ? (
          <>
            <div className={`${labelCss} row-start-2`}>Zletilý/zletilá</div>
            <div className={`${requiredValue} row-start-2`}>Ano</div>
          </>
        ) : null}
        {isPEP ? (
          <>
            <div className={`${labelCss} row-start-3`}>
              Politicky exponovaná osoba
            </div>
            <div className={`${requiredValue} row-start-3`}>Ano</div>
          </>
        ) : null}
      </>
    )
  }

  if (scope === "profile.phonenumber") {
    const value = data as string

    return (
      <>
        {isRequired && register && (
          <>
            <div className={labelCss}>Telefon</div>
            <input
              data-testid={`input-${scope}`}
              {...register(scope)}
              type="hidden"
              key={scope}
              value={scope}
            />
          </>
        )}
        {!isRequired && control && (
          <Checkbox
            control={control}
            name={scope}
            value={scope}
            defaultValue={scope}
            label="Telefon"
            labelTextClassName={checkboxTextCss}
            className={checkboxCss}
          />
        )}
        <div className={optionalValue}>{value}</div>
      </>
    )
  }

  if (isRequired && register) {
    return (
      <input
        data-testid={`input-${scope}`}
        {...register(scope)}
        type="hidden"
        key={scope}
        value={scope}
      />
    )
  }

  return null
}

export default FormattedConsent
