import { FC, ReactElement, useEffect, useState } from "react"

interface Props {
  currentVersion: string
  isEnabled: boolean
  verbose: boolean
  loadingComponent: ReactElement
  onCacheClear?(): void
}

const CacheBuster: FC<Props> = ({
  children,
  currentVersion,
  isEnabled,
  verbose,
  loadingComponent,
  onCacheClear,
}) => {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  })

  const log = (message: string, isError = false) => {
    // with verbose flag true for debug purposes, make eslint allow logs
    // eslint-disable-next-line
    verbose && (isError ? console.error(message) : console.log(message))
  }

  useEffect(() => {
    isEnabled ? checkCacheStatus() : log("Cache busting is disabled.")
    // eslint-disable-next-line
  }, [])

  const checkCacheStatus = async () => {
    try {
      const res = await fetch("/meta.json")
      const { version: metaVersion } = await res.json()

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion)
      if (shouldForceRefresh) {
        log(`There is a new version (${metaVersion}). Should force refresh.`)
        log(`Cached version is old (${currentVersion}). Should force refresh.`)
        setCacheStatus({
          loading: false,
          isLatestVersion: false,
        })
      } else {
        log("There is no new version. No cache refresh needed.")
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        })
      }
    } catch (error) {
      log("An error occurred while checking cache status.", true)
      log(error, true)

      // If there is an error, when verbose is false, abort the cache checking and treat cache as being up-to-date.
      !verbose &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        })
    }
  }

  const isThereNewVersion = (buildVersion: string, cachedVersion: string) => {
    return buildVersion !== cachedVersion
  }

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window
        const cacheNames = await caches.keys()
        for (const cacheName of cacheNames) {
          caches.delete(cacheName)
        }
        log("The cache has been deleted.")
        window.location.reload()
      }
    } catch (error) {
      log("An error occurred while deleting the cache.", true)
      log(error, true)
    }
  }

  if (!isEnabled) {
    return <>{children}</>
    // eslint-disable-next-line
} else {
    if (cacheStatus.loading) {
      return loadingComponent
    }

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      onCacheClear && onCacheClear()
      refreshCacheAndReload()
      return null
    }
    return <>{children}</>
  }
}

export default CacheBuster
