import React, { FC } from "react"
import { useErrorHandler } from "react-error-boundary"
import { useRecoilState, useRecoilValue } from "recoil"
import axios from "axios"
import classNames from "classnames"

import { aBearerToken, aLoading, FilledBearerToken } from "../../state/global"
import { aApaSessionId } from "../../state/auxiliary"
import { execResendSms } from "../../requests/authentication"

import { StyledComponent } from "../../types"

const ResendSms: FC<StyledComponent> = ({ className }) => {
  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken

  const css = classNames("text-xs underline hover:no-underline", className)

  const resendSms = async () => {
    setLoading(true)

    try {
      await execResendSms({ sessionId, bearerToken })
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      data-testid="button-resend-sms"
      type="button"
      onClick={resendSms}
      className={css}
      disabled={isLoading}
    >
      Poslat SMS kód znovu
    </button>
  )
}

export default ResendSms
