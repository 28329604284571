import { useCallback, useEffect, useState } from "react"
import { useBoolean, useInterval } from "react-use"
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import { decode } from "jsonwebtoken"
import {
  aBearerToken,
  aDontLogRequestError,
  aPhase,
  aQrBearerToken,
  aServiceProvider,
  aTimeouted,
  BearerToken,
  FilledBearerToken,
} from "../state/global"
import { aApaSessionId } from "../state/auxiliary"
import {
  execGetQrToken,
  execLoginStatus,
  execQrLoginGetState,
} from "../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "./use-process-authentication-response"

import { sHandleLoginError } from "../state/management"
import isSafariNetworkError from "../utils/safari-network-error"

type QRValue = string | undefined

type ReturnValue = {
  qrCode: QRValue
  counter: number
}

interface UseHandleQr {
  (): ReturnValue
}

/**
 * Takes care of
 * - getting QR content
 * - refreshing it every 20 seconds
 * - updating JWT with a new one received
 * - transitioning to "login" phase (enabling <LoginForm />)
 */
const useHandleQr: UseHandleQr = () => {
  const handleError = useErrorHandler()
  const [phase, setPhase] = useRecoilState(aPhase)
  const appId = useRecoilValue(aServiceProvider)
  const sessionId = useRecoilValue(aApaSessionId)
  const timeouted = useRecoilValue(aTimeouted)
  const [qrCodeScanned, setQrCodeScanned] = useState(false)
  const [qrBearerToken, setQrBearerToken] = useRecoilState(aQrBearerToken)
  const [bearerToken, setBearerToken] = useRecoilState(aBearerToken) as [
    FilledBearerToken,
    SetterOrUpdater<BearerToken>
  ]
  const [, handleLoginError] = useRecoilState(sHandleLoginError)
  const setDontLogRequestError = useSetRecoilState(aDontLogRequestError)
  const [qrCode, setQrCode] = useState<QRValue>()
  const [isRunning, setIsRunning] = useBoolean(false)
  const [counters, setCounters] = useState({
    getQrCounter: 0,
    getStateCounter: 0,
  })
  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()
  const [getQrTokenCancel] = useState(axios.CancelToken.source())
  const [qrLoginGetStateCancel] = useState(axios.CancelToken.source())
  const [loginStatusCancel] = useState(axios.CancelToken.source())

  const { getQrCounter, getStateCounter } = counters

  useProcessAuthenticationResponse(fetchResult)

  /**
   * Used to gets the QR token initially and fetch a new one 20 seconds later
   *
   * handler function needs useCallback, because of its initial use in useEffect
   * and the dependencies it relies on
   */
  const getQRTokenHandler = useCallback(async () => {
    if (timeouted) {
      getQrTokenCancel.cancel()
      qrLoginGetStateCancel.cancel()
      loginStatusCancel.cancel()
      setIsRunning(false)
      return
    }

    setCounters({
      ...counters,
      getQrCounter: getQrCounter + 1,
    })
    if (!qrBearerToken) {
      return
    }

    try {
      // stop from queueing up further calls before current one is resolved
      setIsRunning(false)

      // call the backend and handle the return values
      const { authToken, qrContent } = await execGetQrToken({
        appId,
        bearerToken: qrBearerToken,
        sessionId,
        cancelToken: getQrTokenCancel.token,
      })

      setQrBearerToken(authToken)
      setQrCode(qrContent)

      // initialising QR precludes the ability to render login form
      if (phase === "qrInit") {
        setPhase("login")
      }

      // queue up another call
      setIsRunning(true)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      setIsRunning(false)

      handleError(error)
    }
  }, [
    appId,
    counters,
    getQrCounter,
    getQrTokenCancel,
    handleError,
    loginStatusCancel,
    phase,
    qrBearerToken,
    qrLoginGetStateCancel,
    sessionId,
    setIsRunning,
    setPhase,
    setQrBearerToken,
    timeouted,
  ])

  /**
   * Called once after QR code is received and runs for up to 15 minutes
   * waiting for the user to initialize QR login flow by scanning the code
   * in mobile app
   *
   * After timing out, QR option is removed from the page altogether
   *
   * TODO resolve adding it back for SWT_OFFLINE
   */
  const qrLoginGetStateHandler = useCallback(async () => {
    try {
      if (timeouted) {
        getQrTokenCancel.cancel()
        qrLoginGetStateCancel.cancel()
        loginStatusCancel.cancel()
        setIsRunning(false)
        return
      }

      setCounters({
        ...counters,
        getStateCounter: getStateCounter + 1,
      })

      const { authToken, loginState } = await execQrLoginGetState({
        bearerToken: qrBearerToken as FilledBearerToken,
        sessionId,
        cancelToken: qrLoginGetStateCancel.token,
      })

      if (authToken) {
        // TODO: find better solution with BE
        // only case when ERROR and authId === null is when user dont have BandID active and tries to scan QR code
        if (
          loginState === "ERROR" &&
          decode(authToken, {
            complete: true,
            json: true,
          })?.payload.additionalData?.authId === null
        ) {
          handleLoginError({ loginPhaseError: `NIA_OPTED_OUT` })
          return
        }

        setBearerToken(authToken)
      }

      if (loginState !== "IN_PROGRESS") {
        handleLoginError({ loginPhaseError: `QR_${loginState}` })
      } else {
        setQrCodeScanned(true)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      /**
       * 408 and 504 timeout errors are recoverable and we can re-queue another
       * call to status by resetting the counter
       */
      if (
        axios.isAxiosError(error) &&
        (error.response?.status === 408 ||
          error.response?.status === 504 ||
          error.response?.status === 502)
      ) {
        setCounters({
          ...counters,
          getStateCounter: 1,
        })

        return
      }

      setIsRunning(false)
      setQrCode(undefined)

      if (axios.isAxiosError(error) && isSafariNetworkError(error)) {
        return
      }

      if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
        return
      }

      setDontLogRequestError(false)
      handleError(error)
    }
  }, [
    timeouted,
    counters,
    getStateCounter,
    qrBearerToken,
    sessionId,
    qrLoginGetStateCancel,
    getQrTokenCancel,
    loginStatusCancel,
    setIsRunning,
    setBearerToken,
    handleLoginError,
    setDontLogRequestError,
    handleError,
  ])

  /**
   * Similar to /authentication/attempt or /authentication/waitStatusChange
   * allows the app to progress further by retreiving the next step
   *
   * For QR login flow, the next step should almost always be SWT_ONLINE
   */
  const getStateHandler = useCallback(async () => {
    try {
      const statusResult = await execLoginStatus({
        bearerToken,
        sessionId,
        cancelToken: loginStatusCancel.token,
      })

      setFetchResult(statusResult)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    }
  }, [bearerToken, handleError, loginStatusCancel.token, sessionId])

  /**
   * set up interval to repeat calls to /getQrToken to refresh the displayed
   * code the user can scan
   */
  useInterval(getQRTokenHandler, isRunning ? 20000 : null)

  /**
   * initial call when /getQrToken has not been called yet
   * useEffect prevents calls before component mounts
   * why is this needed:
   * useInterval is not starting right away it will wait 20 sec (dealy) and then start
   * getQRTokenHandler will also set is running to true so useInterval will be started after init
   */
  useEffect(() => {
    if (getQrCounter === 0) {
      getQRTokenHandler()
    }
  }, [getQRTokenHandler, getQrCounter])

  /**
   * follow up to /getQrToken above, long polling call to check if user
   * has started QR login flow
   */
  useEffect(() => {
    if (qrCode && getStateCounter <= 0) {
      qrLoginGetStateHandler()
    }
  }, [getStateCounter, qrCode, qrLoginGetStateHandler])

  /**
   * When LOPI returns IN_PROGRESS state, we need to ask APA backend what to do
   * next and update
   */
  useEffect(() => {
    if (phase === "login" && bearerToken && qrCodeScanned) {
      getStateHandler()
    }
  }, [bearerToken, getStateHandler, phase, qrCodeScanned])

  /**
   * This serves as de facto `domponentDidUnmount` and cancels all running
   * requests to avoid updating state on unmounted component
   */
  useEffect(() => {
    return () => {
      getQrTokenCancel.cancel()
      qrLoginGetStateCancel.cancel()
      loginStatusCancel.cancel()
    }
  }, [getQrTokenCancel, loginStatusCancel, qrLoginGetStateCancel])

  return { qrCode, counter: getQrCounter }
}

export default useHandleQr
