import React, { FC } from "react"

const NiaDefault: FC = () => (
  <>
    <h1 className="text-lg font-bold pb-6 mb-8 border-b border-solid border-black-da">
      Portál národního bodu pro&nbsp;identifikaci a&nbsp;autentizaci vás žádá
      o&nbsp;ověření vaší totožnosti.
    </h1>
    <p className="mb-6 text-sm">
      Přihlášení pomocí bankovní identity Air Bank.
    </p>
    <strong className="mb-4 text-sm">
      Než se přihlásíte, zkontrolujte pár bezpečnostních tipů.
    </strong>
    <ul className="ul space-y-4 text-sm">
      <li>
        Na tuto stránku jste nepřišli proklikem z e-mailu nebo internetového
        vyhledávače.
      </li>
      <li>V adresním řádku vidíte očekávanou adresu stránky.</li>
      <li>
        Když kliknete na zámeček v adresním řádku, uvidíte certifikát vystavený
        pro společnost, do které se přihlašujete.
      </li>
    </ul>
  </>
)

export default NiaDefault
