import axios, { AxiosRequestConfig, AxiosTransformer } from "axios"

import { CancellableRequest } from "./types"
import { FilledBearerToken } from "../../state/global"
import { RSAPublicKey } from "../../state/auxiliary"
import { LoginStep } from "../../state/login-step"

/**
 * Data transformation
 */
type InputData = Partial<
  Record<NonNullable<LoginStep["nextAuthorizationStep"]>, string>
>

export type OutputData = {
  authorizationElementType: keyof InputData
  authCode: NonNullable<InputData[keyof InputData]>
}

interface DataTransform {
  (input: InputData): OutputData[]
}

/**
 * Normalizes data sent to BE
 *
 * Turns
 * ```js
 * {
 *   SMS: 123123,
 *   PWD: "passphrase"
 * }
 * ```
 *
 * into
 * ```js
 * [
 *   {
 *     authorizationElementType: "SMS"
 *     authCode: "123123"
 *   },
 *   {
 *     authorizationElementType: "PWD"
 *     authCode: "passphrase"
 *   }
 * ]
 * ```
 */
export const transformAttemptData: DataTransform = data => {
  const keys = Object.keys(data) as Array<keyof InputData>

  return keys.map(key => ({
    authorizationElementType: key,
    authCode: data[key] as string,
  }))
}

interface TransformRequest {
  (data: InputData): { attempts: OutputData[] }
}

export const transformRequest: TransformRequest = requestData => {
  return {
    attempts: transformAttemptData(requestData),
  }
}

/**
 * ATTEMPT
 */
export type AttemptResponse = {
  authorizationAttemptResult: LoginStep["authorizationAttemptResult"]
  authorizationStatus: NonNullable<LoginStep["authorizationStatus"]>
  nextAuthorizationStep: LoginStep["nextAuthorizationStep"]
  rsaPublicKey?: RSAPublicKey
  swtOnlineDeviceName?: string
  swtOfflineFollowUpData?: string | null
  swtOnlineValidityInSeconds?: number
}

type AttemptRequest = CancellableRequest &
  InputData & {
    sessionId: string
    bearerToken: FilledBearerToken
  }

interface ExecLoginAttempt {
  (data: AttemptRequest): Promise<AttemptResponse>
}

const execLoginAttempt: ExecLoginAttempt = async data => {
  const { sessionId, bearerToken, cancelToken, ...rest } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
    // TODO
  }

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${bearerToken}` },
    transformRequest: [
      transformRequest,
      ...(axios.defaults.transformRequest as AxiosTransformer[]),
    ],
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  // TODO handle 400 errors?
  const response = await axios.post<AttemptResponse>(
    `/api/apa/v1/authentication/attempt?${sessionId}`,
    rest,
    config
  )

  const {
    authorizationAttemptResult,
    authorizationStatus,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOfflineFollowUpData,
    swtOnlineValidityInSeconds,
  } = response.data

  return {
    authorizationAttemptResult,
    authorizationStatus,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOfflineFollowUpData,
    swtOnlineValidityInSeconds,
  }
}

export default execLoginAttempt
