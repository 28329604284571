import React, { FC, useState, useEffect, createRef } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import { aBearerToken, aLoading, FilledBearerToken } from "../../state/global"
import {
  aApaSessionId,
  aRsaPublicKey,
  RSAPublicKey,
} from "../../state/auxiliary"
import { execLoginAttempt } from "../../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "../../hooks/use-process-authentication-response"
import { encryptPassword } from "../../utils/password-crypto"

import Button from "../ui/button"
import ResendSms from "./resend-sms"
import TextField from "../ui/text-field/index"

interface Form {
  PWD: string
  SMS: string
}

const PasswordAndSms: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm<Form>({
    mode: "onSubmit",
  })
  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const rsaPublicKey = useRecoilValue(
    aRsaPublicKey
  ) as NonNullable<RSAPublicKey>
  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()

  useProcessAuthenticationResponse(fetchResult)

  const passwordRef = createRef<HTMLInputElement>()

  const onSubmit: SubmitHandler<Form> = async data => {
    setLoading(true)
    passwordRef.current?.focus()

    const modifiedData = data

    if (rsaPublicKey) {
      modifiedData.PWD = await encryptPassword(data.PWD, rsaPublicKey)
    }

    try {
      const attemptResult = await execLoginAttempt({
        sessionId,
        bearerToken,
        ...modifiedData,
      })
      setFetchResult(attemptResult)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ PWD: "", SMS: "" })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="PWD"
        ref={passwordRef}
        label="Heslo"
        defaultValue=""
        rules={{
          required: "Toto pole je povinné",
        }}
        type="password"
        autoComplete="off"
        autoFocus
        hasVirtualKeyboard
        inputTestId="input-password"
      />

      <TextField
        control={control}
        name="SMS"
        label="Kód z SMS"
        defaultValue=""
        rules={{
          required: "Toto pole je povinné",
        }}
        type="password"
        autoComplete="off"
        inputTestId="input-sms"
      />

      <Button
        testId="button-continue"
        type="submit"
        className="w-full mt-6"
        disabled={isLoading}
      >
        Pokračovat
      </Button>

      <ResendSms className="mt-4" />
    </form>
  )
}

export default PasswordAndSms
