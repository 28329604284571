import { useClickAway } from "react-use"
import React, { FC, useRef } from "react"
import classNames from "classnames"
import { ReactComponent as Close } from "../../../assets/circleClose.svg"

import styles from "./index.module.css"

interface ModalProps {
  type?: "accept" | "warning"
  title?: string
  hideClose?: boolean
  onClose?: () => void
}

const Modal: FC<ModalProps> = ({
  children,
  type = "accept",
  hideClose = false,
  title,
  onClose = () => {},
}) => {
  const css = classNames(
    type === "accept" ? styles.headAccept : styles.headWarning,
    "grid grid-cols-2 rounded-t-2xl px-4 py-3 items-center leading-none"
  )
  const ref = useRef(null)

  useClickAway(ref, () => onClose())

  return (
    <div className="flex fixed justify-center items-center bg-black-full  bg-opacity-50 h-screen w-screen inset-0 z-10">
      <div
        ref={ref}
        className="max-w-screen-sm rounded-2xl bg-white mx-3 my-5 sm:my-0 text-sm"
      >
        <div className={css}>
          <div className="col-start-1 col-span-1 self-middle">
            <strong className="text-lg">{title}</strong>
          </div>
          {!hideClose && (
            <div className="col-start-2 col-span-1 flex justify-self-end">
              <button
                onClick={() => onClose()}
                data-testid="button-modal-close"
              >
                <Close />
              </button>
            </div>
          )}
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  )
}

export default Modal
