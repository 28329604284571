import React, { FC } from "react"

const TokenInvalid: FC = () => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">Tudy to nepůjde</h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="text-sm">
        Vypadá&nbsp;to, že&nbsp;se&nbsp;pokoušíte přihlásit přes&nbsp;odkaz,
        který už&nbsp;není&nbsp;platný.
      </p>
      <p className="mt-4 text-sm">
        U&nbsp;každého přihlášení je potřeba začít vždy od&nbsp;začátku, tedy
        ze&nbsp;služby, kterou si&nbsp;přejete využít.
        Pokud&nbsp;si&nbsp;například přejete ověřit svou&nbsp;totožnost pomocí
        bankovní identity v&nbsp;Portálu&nbsp;občana, je potřeba začít
        na&nbsp;stránkách Portálu&nbsp;občana.
      </p>
    </>
  )
}

export default TokenInvalid
