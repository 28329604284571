import React, { FC, useRef, createRef, useState } from "react"
import { Redirect } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useClickAway } from "react-use"
import { SubmitHandler, useForm } from "react-hook-form"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"

import {
  aOperationType,
  aBearerToken,
  aLoading,
  aPhase,
  aPreloginAppName,
  aPreloginProviderName,
  aServiceProvider,
  FilledBearerToken,
} from "../../state/global"
import { aApaSessionId } from "../../state/auxiliary"
import {
  aConsents,
  aIdPortState,
  aIdPortToken,
  aIdPortTraceState,
  ProfileScopes,
  Scopes,
} from "../../state/idport"
import { aRedirectUrl } from "../../state/login-step"
import { execFinishAuthorization } from "../../requests/finish-authorization"

import Button from "../../components/ui/button"
import Footer from "../../components/footer/footer"
import FormattedConsent, {
  FormData,
} from "../../components/format/formatted-consent"
import Header from "../../components/header/header"
import InfoTooltip from "../../components/ui/info-tooltip"
import RedirectForm from "../../components/redirect-form"

import { ReactComponent as CaretRight } from "../../assets/caret-right.svg"
import { ReactComponent as Close } from "../../assets/close.svg"
import execRejectAuthorization from "../../requests/reject-authorization"
import SignCancelationModal from "../../components/modals/sign-cancelation-modal"

interface SortScopes {
  (firstScope: Scopes, secondScope: Scopes): number
}

const sortScopes: SortScopes = (firstScope, secondScope) => {
  const scopeDisplayOrder = {
    "profile.name": 0,
    "profile.titles": 1,
    "profile.gender": 2,
    "profile.birthdate": 3,
    "profile.birthnumber": 4,
    "profile.birthplaceNationality": 5,
    "profile.maritalstatus": 6,
    "profile.addresses": 7,
    "profile.idcards": 8,
    "profile.paymentAccounts": 9,
    "profile.email": 10,
    "profile.phonenumber": 11,
    "profile.updatedat": 12,
    "profile.legalstatus": 13,
    "profile.zoneinfo": 14,
    "profile.locale": 15,
    "notification.claims_updated": 16,
    offline_access: 17,
  }

  return scopeDisplayOrder[firstScope] - scopeDisplayOrder[secondScope]
}

const Consents: FC = () => {
  const handleError = useErrorHandler()
  const setLoading = useSetRecoilState(aLoading)
  const apaSessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const serviceProvider = useRecoilValue(aServiceProvider)
  const { mandatoryConsents, requiredConsents } = useRecoilValue(aConsents)
  const operationType = useRecoilValue(aOperationType)
  const setToken = useSetRecoilState(aIdPortToken)
  const setState = useSetRecoilState(aIdPortState)
  const setTracestate = useSetRecoilState(aIdPortTraceState)
  const setRedirectUrl = useSetRecoilState(aRedirectUrl)
  const [phase, setPhase] = useRecoilState(aPhase)
  const providerName = useRecoilValue(aPreloginProviderName)
  const appName = useRecoilValue(aPreloginAppName)
  const [isScopeInfoHidden, hideScopeInfo] = useState(true)
  const [submitCancelation, setSubmitCancelation] = useState(false)
  const scopeTooltipRef = createRef<HTMLDivElement>()
  useClickAway(scopeTooltipRef, () => hideScopeInfo(true))
  const hiddenSubmit = useRef<HTMLButtonElement>(null)
  const { control, handleSubmit, register } = useForm<FormData>()
  const [openModal, setOpenModal] = useState(false)

  if (!serviceProvider) {
    return <Redirect to="/" />
  }

  const scopeClasses = [
    `col-start-1 col-span-1 row-start-1 row-span-1`,
    `col-start-1 col-span-1 row-start-2 row-span-1`,
    `col-start-1 col-span-1 row-start-3 row-span-1`,
    `col-start-1 col-span-1 row-start-4 row-span-1`,
    `col-start-1 col-span-1 row-start-5 row-span-1`,
    `col-start-1 col-span-1 row-start-6 row-span-1`,
    `col-start-1 col-span-1 row-start-7 row-span-1`,
    `col-start-1 col-span-1 row-start-8 row-span-1`,
    `col-start-1 col-span-1 row-start-9 row-span-1`,
    `col-start-1 col-span-1 row-start-10 row-span-1`,
    `col-start-1 col-span-1 row-start-11 row-span-1`,
    `col-start-1 col-span-1 row-start-12 row-span-1`,
    `col-start-1 col-span-1 row-start-13 row-span-1`,
    `col-start-1 col-span-1 row-start-14 row-span-1`,
    `col-start-1 col-span-1 row-start-15 row-span-1`,
    `col-start-1 col-span-1 row-start-16 row-span-1`,
    `col-start-1 col-span-1 row-start-17 row-span-1`,
    `col-start-1 col-span-1 row-start-18 row-span-1`,
    `col-start-1 col-span-1 row-start-19 row-span-1`,
    `col-start-1 col-span-1 row-start-20 row-span-1`,
  ]

  const onSubmit: SubmitHandler<FormData> = async formData => {
    setLoading(true)

    let consents = [] as Scopes[]

    if (formData) {
      // if any consents are in form, transform consents from formData
      let profileDataKeys = [] as ProfileScopes[]
      if (formData.profile) {
        profileDataKeys = Object.keys(formData.profile) as ProfileScopes[]
      }
      const consentsArray = profileDataKeys
        .filter(profileDataKey => !!formData.profile[profileDataKey])
        .map(profileDataKey => `profile.${profileDataKey}`) as Scopes[]

      if (
        formData.notification?.claims_updated &&
        (formData.offline_access === "offline_access" ||
          mandatoryConsents["notification.claims_updated"] === "" ||
          (!requiredConsentScopes.includes("offline_access") &&
            !mandatoryConsentScopes.includes("offline_access")))
      ) {
        consentsArray.push("notification.claims_updated")
      }

      if (formData.offline_access) {
        consentsArray.push("offline_access")
      }

      consents = consentsArray
    }

    const data = { apaSessionId, bearerToken, consents }

    try {
      if (operationType === "AUTHENTICATION" && consents.length < 1) {
        hideScopeInfo(false)
        return
      }
      const result = await execFinishAuthorization(data)

      const { redirect } = result

      const { redirectUrl, state, token3, tracestate } = redirect
      setToken(token3)
      setState(state)
      setTracestate(tracestate)
      setRedirectUrl(redirectUrl)
      setPhase("redirect")
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const onRejectAuth = async () => {
    try {
      await execRejectAuthorization({
        bearerToken,
        rejectionReason: "CONSENTS_REJECTED",
      })
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setSubmitCancelation(true)
    }
  }

  if (phase === "redirect") {
    return <Redirect to="/" />
  }

  const requiredConsentScopes = Object.keys(requiredConsents) as Scopes[]
  const mandatoryConsentScopes = Object.keys(mandatoryConsents) as Scopes[]

  const hasClaimsNotification = [
    ...requiredConsentScopes,
    ...mandatoryConsentScopes,
  ].includes("offline_access")
  const claimsNotificationRequired =
    mandatoryConsentScopes.includes("offline_access")

  const noConsentsTooltipContent =
    "Vypadá to, že poskytovateli služby žádnou informaci předat nechcete. Je-li to tak, zvolte možnost Zamítnout a odejít. V opačném případě vyberte alespoň jeden údaj, který můžeme předat."

  return (
    <>
      <section className="mx-auto max-w-5xl px-6">
        <Header />
        <div className="flex flex-col sm:block relative bg-white border border-solid border-black-da rounded-md rounded-t-none">
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-5 pt-3">
              <h1 className="text-lg font-bold">
                {operationType === "DOCUMENT_SIGN"
                  ? "Souhlas s předáním údajů"
                  : "Pokyn k předání údajů"}
              </h1>
              <hr className="mt-2 mb-4 border-t border-solid border-black-da" />

              {operationType === "DOCUMENT_SIGN" ? (
                <p className="text-xs">
                  Potvrzením souhlasíte s předáním Vašich osobních údajů
                  společnosti Bankovní identita, a.s. a společnosti{" "}
                  <strong>{providerName}</strong> pro službu{" "}
                  <strong>{appName}</strong> za účelem ověření a/nebo vytvoření
                  elektronického podpisu. Zároveň dávate společnosrti Bankovní
                  identa, a.s. pokyn k připojení podpisu k dokumentu a/nebo
                  prohlášení.
                </p>
              ) : (
                <p className="text-xs">
                  Potvrzením souhlasíte s předáním vašich osobních údajů
                  poskytovateli služeb <strong>{providerName}</strong> pro vstup
                  do aplikace <strong>{appName}</strong> za účelem vašeho
                  ověření. Údaje se předávají prostřednictvím společnosti
                  Bankovní identita a.s.
                </p>
              )}

              <p className="mt-2 text-xs">
                Než budete pokračovat, zkontrolujte si prosím správnost
                předávaných údajů. Další informace najdete v dokumentu{" "}
                <a
                  data-testid="link-personal-privacy"
                  href="https://www.airbank.cz/osobni_udaje"
                  target="_blank"
                  className="underline hover:no-underline"
                  rel="noopener noreferrer"
                >
                  Informace o zpracování osobních údajů
                </a>
                .
              </p>
              {mandatoryConsentScopes.length > 0 && (
                <>
                  <hr className="my-4 border-t border-solid border-black-da" />
                  <h2 className="text-base font-bold">Povinné údaje</h2>
                  <div className="grid grid-cols-1 mx-auto pl-4 sm:pl-24 md:pl-56 lg:pl-64 mt-4 w-full text-xs break-words gap-y-3">
                    {mandatoryConsentScopes
                      .sort(sortScopes)
                      .filter(
                        scope =>
                          scope !== "offline_access" &&
                          scope !== "profile.updatedat" &&
                          scope !== "profile.locale" &&
                          scope !== "profile.zoneinfo"
                      )
                      .map((scope, index) => (
                        <div key={scope} className={scopeClasses[index]}>
                          <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-x-6 gap-y-3">
                            <FormattedConsent
                              data={mandatoryConsents[scope]}
                              scope={scope}
                              register={register}
                              control={control}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {hasClaimsNotification && claimsNotificationRequired && (
                <FormattedConsent
                  data="offline_access"
                  scope="offline_access"
                  isRequired={claimsNotificationRequired}
                  control={control}
                  register={register}
                />
              )}
              {requiredConsentScopes.length - mandatoryConsentScopes.length >
                0 && (
                <>
                  <hr className="my-4 border-t border-solid border-black-da" />
                  <h2 className="text-base font-bold">Volitelné údaje</h2>
                  <div className="grid grid-cols-1 mx-auto pl-4 sm:pl-24 md:pl-56 lg:pl-64 mt-4 gap-y-3 w-full text-xs break-words">
                    {requiredConsentScopes
                      .filter(scope => !mandatoryConsentScopes.includes(scope))
                      .filter(
                        scope =>
                          scope !== "offline_access" &&
                          scope !== "profile.updatedat" &&
                          scope !== "profile.locale" &&
                          scope !== "profile.zoneinfo"
                      )
                      .sort(sortScopes)
                      .map((scope, index) => (
                        <div key={scope} className={scopeClasses[index]}>
                          <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 grid-row-1 gap-x-8 gap-y-3">
                            <FormattedConsent
                              data={requiredConsents[scope]}
                              scope={scope}
                              isRequired={false}
                              control={control}
                              register={register}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {hasClaimsNotification && !claimsNotificationRequired && (
                <FormattedConsent
                  data="offline_access"
                  scope="offline_access"
                  isRequired={claimsNotificationRequired}
                  control={control}
                  register={register}
                />
              )}
              {!isScopeInfoHidden ? (
                <InfoTooltip
                  ref={scopeTooltipRef}
                  variant="error"
                  infoText={noConsentsTooltipContent}
                  className="-mt-16 sm:-mt-12 bottom-0 mb-20"
                />
              ) : null}
              {requiredConsentScopes
                .filter(scope => /^profile/.test(scope))
                .filter(
                  scope =>
                    scope === "profile.updatedat" ||
                    scope === "profile.locale" ||
                    scope === "profile.zoneinfo"
                )
                .sort(sortScopes)
                .map(scope => (
                  <FormattedConsent
                    key={scope}
                    data={requiredConsents[scope]}
                    scope={scope}
                    control={control}
                    register={register}
                  />
                ))}
            </div>
            <button
              data-testid="button-submit-consent-form"
              type="submit"
              ref={hiddenSubmit}
              className="hidden"
              aria-label="Submit form"
            />
          </form>
          <div className="flex justify-between items-center text-xs px-5 py-4 border-t border-solid border-black-da">
            {operationType === "DOCUMENT_SIGN" ? (
              <button
                type="button"
                className="flex items-center underline hover:no-underline"
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                <Close className="mr-2" />
                Zamítnout a odejít
              </button>
            ) : (
              <>
                <button
                  data-testid="button-reject-consent-form"
                  type="button"
                  className="flex items-center underline hover:no-underline"
                  onClick={onRejectAuth}
                >
                  <Close className="mr-2" />
                  Zamítnout a odejít
                </button>
                <RedirectForm autoSubmit={submitCancelation} action="CANCEL" />
              </>
            )}

            <Button
              data-testid="button-confirm-consent-form"
              variant="green"
              className="flex items-center px-6"
              onClick={() => {
                hiddenSubmit.current?.click()
              }}
            >
              Potvrdit
              <CaretRight className="ml-4" />
            </Button>
          </div>
        </div>

        <Footer />
        {openModal && operationType === "DOCUMENT_SIGN" && (
          <SignCancelationModal
            callbackModal={() => {
              setOpenModal(false)
            }}
          />
        )}
      </section>
    </>
  )
}

export default Consents
