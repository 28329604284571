/**
 * This utility is used to decrypt user password for testing purposes
 *
 * We use the private part of a RSA key provided by BE to decrypt the password
 * to verify our public key encryption works as intended.
 */
import "promiz"
import "webcrypto-shim"
import { stringToArrayBuffer } from "./helpers"

interface ImportPrivateKey {
  (pem: string): Promise<CryptoKey>
}

/**
 * Imports a string representation of a public RSA key using `window.crypto`,
 * first converting the string key into a binary ArrayBuffer representation
 */
const importPrivateKey: ImportPrivateKey = async pem => {
  const binaryDer = stringToArrayBuffer(pem, "binary")
  /* eslint-disable-next-line */
  /* @ts-ignore */
  const cryptoObj = window.crypto || window.msCrypto /* IE11 native */
  const result = await cryptoObj.subtle.importKey(
    "pkcs8",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["decrypt"]
  )
  return result
}

interface DecryptDataWithPrivateKey {
  (data: string, key: CryptoKey): Promise<ArrayBuffer>
}

/**
 * Encrypts given string data with a key obtained from `window.crypto`
 * importKey using an RSA public key
 */
const decryptDataWithPrivateKey: DecryptDataWithPrivateKey = async (
  data,
  key
) => {
  /* eslint-disable-next-line */
  /* @ts-ignore */
  const cryptoObj = window.crypto || window.msCrypto /* IE11 native */
  return cryptoObj.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    key,
    stringToArrayBuffer(data, "binary")
  )
}

interface PasswordDecrypt {
  (ciphertext: string, publicKey: string): Promise<string>
}

/**
 * Decrypts given ciphertext using given RSA key using `window.crypto`
 *
 * @see https://stackoverflow.com/a/62967202
 */
const decryptPassword: PasswordDecrypt = async (ciphertext, privateKey) => {
  const decryptionKey = await importPrivateKey(privateKey)
  const decryptedPassword = await decryptDataWithPrivateKey(
    ciphertext,
    decryptionKey
  )

  if (window.TextDecoder === undefined) {
    /* eslint-disable-next-line */
    const TextEncodingPolyfill = require('text-encoding')
    return new TextEncodingPolyfill.TextDecoder().decode(decryptedPassword)
  }
  return new TextDecoder().decode(decryptedPassword)
}

export default decryptPassword
