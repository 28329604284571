import { atom } from "recoil"
import { v4 } from "uuid"

/**
 * APA unique session ID used to mark requests for BE
 */
export const aApaSessionId = atom<string>({
  key: "aApaSessionId",
  default: `ReactAPA_${v4()}`,
})

/**
 * RSA public key used to encrypt user passwords
 */
export type RSAPublicKey = string | null

export const aRsaPublicKey = atom<RSAPublicKey>({
  key: "aRsaPublicKey",
  default: null,
})

/**
 * In cases where we need IB redirects, we receive and store IB url
 */
export const aIbUrl = atom<string | undefined>({
  key: "aIbUrl",
  default: undefined,
})
