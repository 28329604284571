import React, { FC } from "react"

const Redirecting: FC = () => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">Chvilku strpení</h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="text-sm">
        Za chvíli bychom vás měli přesměrovat zpět do&nbsp;služby, u&nbsp;které
        jste si&nbsp;přáli ověřit svou totožnost pomocí bankovní identity.
      </p>
      <p className="mt-4 text-sm">
        Pokud k&nbsp;přesměrování nedojde, neproběhlo zřejmě vše správně.
        V&nbsp;takovém případě zkuste prosím začít znovu od&nbsp;začátku.
      </p>
    </>
  )
}

export default Redirecting
