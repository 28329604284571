import { FC, useRef } from "react"
import { useBoolean } from "react-use"
import { useRecoilValue } from "recoil"
import { aLoginPhaseError, aLoginStep } from "../../state/login-step"
import RedirectForm from "../redirect-form"
import Button from "../ui/button"
import Modal from "../ui/modal"

const SemiLockedModal: FC = () => {
  const error = useRecoilValue(aLoginPhaseError)
  const { authorizationAttemptResult } = useRecoilValue(aLoginStep)
  const hiddenButton = useRef<HTMLButtonElement>(null)
  const [showModal, toggle] = useBoolean(false)

  // this is for tiggering submit for RedirectForm (all buttons (and click outside) are connected to this)
  const resetLogin = () => {
    hiddenButton?.current?.click()
  }

  if (
    (!showModal && error === "REJECTED_LOGOUT") ||
    authorizationAttemptResult === "NOK_LOGOUT"
  ) {
    toggle()
  }

  return (
    <>
      {showModal && (
        <RedirectForm autoSubmit={false} action="CANCEL">
          <Modal title="Nepoznáváme vás" onClose={resetLogin}>
            <p className="pb-1">
              Důvodů může být&nbsp;více, ty&nbsp;hlavní ale&nbsp;jsou - nezadali
              jste&nbsp;správné uživatelské jméno, překlepli jste&nbsp;se
              v&nbsp;hesle do&nbsp;internetového bankovnictví
              nebo&nbsp;v&nbsp;hesle do mobilní aplikace nebo jste z&nbsp;mobilu
              špatně opsali kód.
            </p>
            Ať&nbsp;tak&nbsp;či&nbsp;tak, nezbývá, než se&nbsp;zkusit přihlásit
            znovu.
            <div className="py-1 flex justify-end">
              <Button
                testId="button-confirm-semilocked"
                variant="green"
                onClick={resetLogin}
              >
                Rozumím
              </Button>
            </div>
          </Modal>
          <button
            data-testid="button-submit-semilocked"
            ref={hiddenButton}
            className="hidden"
            type="submit"
          >
            Submit RedirectForm
          </button>
        </RedirectForm>
      )}
    </>
  )
}

export default SemiLockedModal
