import React, { FC } from "react"

import { LoginStep } from "../../state/login-step"

type Props = {
  nextAuthorizationStep: LoginStep["nextAuthorizationStep"]
}

const LastAttempt: FC<Props> = ({ nextAuthorizationStep }) => {
  return (
    <>
      <h2 className="font-bold text-xl mt-4 sm:mt-0">
        Máte poslední pokus o přihlášení
      </h2>
      <hr className="mt-4 mb-6 border-green-brand" />
      <p className="text-sm">
        Než se&nbsp;rozhodnete pokračovat, vězte, že&nbsp;pokud zadáte chybné
        bezpečnostní prvky, zablokujeme vám přístup k&nbsp;vaší bankovní
        identitě i&nbsp;do&nbsp;internetového bankovnictví.
      </p>
      {["SMS", "PWD_AND_SMS"].includes(nextAuthorizationStep || "") && (
        <ul className="ul mt-4">
          <li>
            <div>
              <b>Kód, který&nbsp;vám dorazil</b> při&nbsp;předchozím pokusu
              o&nbsp;přihlášení, je stále platný.{" "}
              <b>Na&nbsp;nový nečekejte a&nbsp;použijte ten původní.</b>
            </div>
          </li>
        </ul>
      )}
    </>
  )
}

export default LastAttempt
