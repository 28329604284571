import React, { FC, useEffect, useState } from "react"
import { useInterval } from "react-use"
import { useResetRecoilState, useSetRecoilState, useRecoilValue } from "recoil"
import format from "date-fns/format"

import { aLoginStep } from "../../state/login-step"
import { aSwtOfflineFollowUpData } from "../../state/swt"

import { AttemptResponse } from "../../requests/authentication/attempt"
import useHandleSwtOnline from "../../hooks/use-handle-swt-online"

import { ReactComponent as Loader } from "../../assets/loader.svg"

type Props = Required<
  Pick<AttemptResponse, "swtOnlineDeviceName" | "swtOnlineValidityInSeconds">
>

const SWTOnline: FC<Props> = ({
  swtOnlineDeviceName,
  swtOnlineValidityInSeconds,
}) => {
  const resetLoginStep = useResetRecoilState(aLoginStep)
  const [timer, setTimer] = useState(swtOnlineValidityInSeconds * 1000)
  const [formattedTime, setFormattedTime] = useState<string | null>()
  const setLoginStep = useSetRecoilState(aLoginStep)

  const dataForSwtOffline = useRecoilValue(aSwtOfflineFollowUpData)

  const finishHandleSwtOnline = useHandleSwtOnline()

  const goOffline = () => {
    setLoginStep({
      authorizationAttemptResult: null,
      authorizationStatus: "IN_PROGRESS",
      nextAuthorizationStep: "SWT_OFFLINE",
    })
  }

  useInterval(
    () => {
      setTimer(timer - 1000)
    },
    timer > 0 ? 1000 : null
  )

  useEffect(() => {
    if (!finishHandleSwtOnline && +timer > 0) {
      setFormattedTime(format(new Date(timer), "mm:ss"))
      return
    }
    if (finishHandleSwtOnline) {
      resetLoginStep()
    }
  }, [resetLoginStep, timer, finishHandleSwtOnline])

  return (
    <>
      <div className="p-5 bg-white rounded-md text-xs">
        <div className="mb-4 pb-4 border-b border-solid border-green-brand">
          Přihlášení nyní potvrďte v&nbsp;<b>mobilní aplikaci</b>{" "}
          na&nbsp;zařízení <b>{swtOnlineDeviceName}</b>.
        </div>
        <div className="flex items-center">
          <Loader className="ml-2 mr-5 w-8 h-8 animate-spin" />
          <div>
            Máte na to
            <br />
            <b>{formattedTime} minut</b>
          </div>
        </div>
      </div>
      <div>
        {dataForSwtOffline ? (
          <button
            data-testid="button-swt-offline"
            className="text-left text-xs underline hover:no-underline"
            onClick={goOffline}
          >
            Nejsem na&nbsp;internetu
          </button>
        ) : null}
      </div>
    </>
  )
}

export default SWTOnline
