import React, { FC } from "react"

const SMS: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">
      Proč jsme vám poslali SMS pro přihlášení?
    </h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <ul className="ul space-y-4 text-sm">
      <li>
        Jednorázový SMS kód je dalším zabezpečením, kterým chráníme vaši
        bankovní identitu.
      </li>
      <li>Kód jsme vám poslali na vaše telefonní číslo.</li>
    </ul>
  </>
)

export default SMS
