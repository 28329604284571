import React, { createRef, FC, useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"
import { useErrorHandler } from "react-error-boundary"
import axios from "axios"
import format from "date-fns/format"

import { aBearerToken, aLoading, FilledBearerToken } from "../../state/global"
import { aApaSessionId } from "../../state/auxiliary"
import { execLoginAttempt } from "../../requests/authentication"
import useProcessAuthenticationResponse, {
  GeneralAuthenticationResponse,
} from "../../hooks/use-process-authentication-response"

import Button from "../ui/button"
import TextField from "../ui/text-field/index"

type Form = {
  DAY: string
  MONTH: string
  YEAR: string
}

interface GetFullBirthday {
  (date: Form): string
}

const getFullBirthday: GetFullBirthday = ({ DAY, MONTH, YEAR }) => {
  const date = new Date(+YEAR, +MONTH - 1, +DAY)
  return format(date, "yyyy-MM-dd")
}

const Birthday: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm<Form>({
    mode: "onSubmit",
  })

  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(aLoading)
  const sessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const [fetchResult, setFetchResult] = useState<
    GeneralAuthenticationResponse | undefined
  >()

  useProcessAuthenticationResponse(fetchResult)

  const minYear = new Date().getFullYear() - 100
  const maxYear = new Date().getFullYear() - 10

  const onSubmit: SubmitHandler<Form> = async data => {
    setLoading(true)

    try {
      const attemptResult = await execLoginAttempt({
        sessionId,
        bearerToken,
        BIRTHDAY: getFullBirthday(data),
      })

      setFetchResult(attemptResult)
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const dayRef = createRef<HTMLInputElement>()
  const monthRef = createRef<HTMLInputElement>()
  const yearRef = createRef<HTMLInputElement>()

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ DAY: "", MONTH: "", YEAR: "" })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <p className="font-bold text-xs leading-6">Datum narození</p>
      <div
        aria-label="Datum narození"
        className="grid grid-cols-4 grid-rows-1 col-gap-2"
      >
        <TextField
          control={control}
          name="DAY"
          title="Zadejte svůj den narození"
          defaultValue=""
          placeholder="DD"
          rules={{
            required: "Den narození je povinné pole.",
            maxLength: 2,
            minLength: 1,
            max: 31,
            min: 1,
          }}
          autoComplete="off"
          autoFocus
          type="number"
          className="col-start-1 col-span-1"
          errorText={errors.DAY?.message}
          ref={dayRef}
          onChange={event => {
            if (event.target.value.length >= 2) {
              monthRef.current?.focus()
            }
          }}
          inputTestId="input-birthday-day"
        />
        <TextField
          control={control}
          name="MONTH"
          title="Zadejte svůj měsíc narození"
          defaultValue=""
          placeholder="MM"
          rules={{
            required: "Měsíc narození je povinné pole.",
            maxLength: 2,
            minLength: 1,
            max: 12,
            min: 1,
          }}
          autoComplete="off"
          type="number"
          className="col-start-2 col-span-1"
          errorText={errors.MONTH?.message}
          ref={monthRef}
          onChange={event => {
            if (event.target.value.length >= 2) {
              yearRef.current?.focus()
            }
          }}
          inputTestId="input-birthday-month"
        />
        <TextField
          control={control}
          name="YEAR"
          title="Zadejte svůj rok narození"
          defaultValue=""
          placeholder="RRRR"
          rules={{
            required: "Rok narození je povinné pole.",
            maxLength: 4,
            minLength: 4,
            max: maxYear,
            min: minYear,
          }}
          autoComplete="off"
          type="number"
          className="col-start-3 col-span-2"
          errorText={errors.YEAR?.message}
          ref={yearRef}
          inputTestId="input-birthday-year"
        />
      </div>
      <Button
        testId="button-continue"
        type="submit"
        className="w-full mt-6"
        disabled={isLoading}
        onClick={() => {
          if (errors.DAY?.type === "required") {
            dayRef.current?.focus()
          } else if (errors.MONTH?.type === "required") {
            monthRef.current?.focus()
          } else if (errors.YEAR?.type === "required") {
            yearRef.current?.focus()
          } else if (errors.DAY || errors.YEAR || errors.YEAR) {
            reset({ DAY: "", MONTH: "", YEAR: "" })
            dayRef.current?.focus()
          }
        }}
      >
        Pokračovat
      </Button>
    </form>
  )
}

export default Birthday
