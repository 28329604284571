import React, { forwardRef } from "react"
import classNames from "classnames"

import styles from "./info-tooltip.module.css"

interface Props {
  infoText?: string
  variant?: "error" | "info"
  className?: string
  onCloseError?: () => void
}

const InfoTooltip = forwardRef<HTMLDivElement, Props>(
  ({ infoText, variant = "error", onCloseError, className }, ref) => {
    const borderCSS = classNames(
      variant === "error"
        ? "bg-error-bg border-error-light"
        : "bg-green-bg border-green-brand",
      "border border-solid border shadow-md rounded-md space-y-1 p-4"
    )

    const beakCSS = classNames("h-4 w-12 transform -rotate-45")

    return (
      <div
        className={`absolute max-w-md right-0 mx-1 z-10 ${className}`}
        ref={ref}
      >
        {variant === "error" ? (
          <>
            <div className="w-2 h-2 overflow-hidden transform -rotate-45 translate-x-4 translate-y-1">
              <div className={`bg-error-light origin-top-left ${beakCSS}`} />
            </div>
            <div className="absolute w-2 h-2 overflow-hidden transform -rotate-45 translate-x-4 scale-150">
              <div className={`bg-error-bg origin-bottom-left ${beakCSS}`} />
            </div>
          </>
        ) : null}
        <div className={borderCSS}>
          {variant === "error" ? (
            <>
              <div className="grid grid-cols-2 items-center">
                <p className="col-start-1 col-span-1 font-bold text-xs">
                  Upozornění
                </p>
                {onCloseError && (
                  <button
                    data-testid="button-tooltip-close"
                    type="button"
                    className={`justify-self-end -mr-3 -mt-4 col-start-2 col-span-1 ${styles.errorClose}`}
                    aria-label="Zavřít"
                    onClick={onCloseError}
                  />
                )}
              </div>
              <hr className="border-black-cc" />
              <p className="font-bold text-sm pt-1 pb-2">
                {infoText || "Bez tohoto údaje se neobejdeme"}
              </p>
              <p className="text-xs">Doplňte jej, prosím.</p>
              <hr className="border-black-cc" />
            </>
          ) : null}
          {variant === "info" ? <p className="text-xs">{infoText}</p> : null}
        </div>
        {variant === "info" ? (
          <div className="transform translate-x-20">
            <div className="w-2 h-2 overflow-hidden transform -rotate-45 translate-x-4 -translate-y-1">
              <div className={`bg-green-brand origin-bottom-left ${beakCSS}`} />
            </div>
            <div className="w-2 h-2 overflow-hidden transform -rotate-45 translate-x-4 -translate-y-4 scale-150">
              <div className={`bg-green-bg origin-bottom-left ${beakCSS}`} />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
)

export default InfoTooltip
