import { atom, selector } from "recoil"
import { validate } from "uuid"

/**
 * service provider such as
 * - internet banking
 * - NIA
 * - BankID (uuid)
 */
export const aPreloginProviderName = atom<string>({
  key: "aPreloginProvider",
  default: "",
})

export type OperationType = "AUTHENTICATION" | "DOCUMENT_SIGN" | ""

/**
 *  type of operation for bankid:
 *  - sign documents or accept consents
 */
export const aOperationType = atom<OperationType>({
  key: "aOperationType",
  default: "",
})

export const aSignRequestId = atom<string>({
  key: "aSignRequestId",
  default: "",
})

export const aPreloginAppName = atom<string>({
  key: "aPreloginApp",
  default: "",
})

export const aUsername = atom<string>({
  key: "aUsername",
  default: "",
})

export type ServiceProvider = "NiaApp" | "IB" | string | undefined

export const aServiceProvider = atom<ServiceProvider>({
  key: "aServiceProvider",
  default: undefined,
})

export const sIsServiceProviderNIA = selector({
  key: "sIsServiceProviderNIA",
  get: ({ get }) => get(aServiceProvider) === "NiaApp",
})

export const sIsServiceProviderBankId = selector({
  key: "sIsServiceProviderBankId",
  get: ({ get }) => validate(get(aServiceProvider) || ""),
})

export const sIsServiceProviderIB = selector({
  key: "sIsServiceProviderIB",
  get: ({ get }) => get(aServiceProvider) === "IB",
})

/**
 * phase
 */
export type PhaseState =
  | "preLogin"
  | "qrInit"
  | "login"
  | "authorized"
  | "postLogin"
  | "consents"
  | "redirect"
  | "error"

export const aPhase = atom<PhaseState>({
  key: "aPhase",
  default: "preLogin",
})

export const aTimeouted = atom<boolean>({
  key: "aTimeouted",
  default: false,
})

export const isPreLoginPhase = selector({
  key: "isPreLoginPhase",
  get: ({ get }) => get(aPhase) === "preLogin",
})

export const isQrInitPhase = selector({
  key: "isQrInitPhase",
  get: ({ get }) => get(aPhase) === "qrInit",
})

export const isLoginPhase = selector({
  key: "isLoginPhase",
  get: ({ get }) => get(aPhase) === "login",
})

export const isPostLoginPhase = selector({
  key: "isPostLoginPhase",
  get: ({ get }) => get(aPhase) === "postLogin",
})

/**
 * `Authorization: Bearer` token
 */
export type BearerToken = string | undefined

export type FilledBearerToken = NonNullable<BearerToken>

export const aBearerToken = atom<BearerToken>({
  key: "aBearerToken",
  default: undefined,
})

export const aQrBearerToken = atom<BearerToken>({
  key: "aQrBearerToken",
  default: undefined,
})

/**
 * Loading state for non-phase related loading states
 */
export const aLoading = atom<boolean>({
  key: "aLoading",
  default: false,
})

/**
 * Overlay display based on phase/fetching
 */
export const sShouldShowOverlay = selector({
  key: "sShouldShowOverlay",
  get: ({ get }) => {
    const phase = get(aPhase)

    const phaseSupportsSpinner = [
      "preLogin",
      "qrInit",
      "authorized",
      "postLogin",
      "redirect",
    ].includes(phase)

    const isSpinnerOn = get(aLoading)

    return phaseSupportsSpinner || isSpinnerOn
  },
})

/**
 * Errors from requests shouldnt be logged (all except for lopi calls)
 */
export const aDontLogRequestError = atom<boolean>({
  key: "aDontLogRequestError",
  default: true,
})

export type BEErrorData = {
  error?: string
  message?: string
  path?: string
  status?: number
  timestamp?: string
}

/**
 * Error data from /api/... requests that end up with 4XX and 5XX status code
 */
export const aBeErrorData = atom<BEErrorData | undefined>({
  key: "aBeErrorData",
  default: undefined,
})
