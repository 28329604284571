import React, { FC } from "react"

const Banner: FC = ({ children }) => {
  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 w-full sm:mt-3 z-100">
      <div className="text-sm text-center text-black sm:rounded-md py-2 m-auto sm:max-w-xs bg-black-eb block">
        {children}
      </div>
    </div>
  )
}

export default Banner
