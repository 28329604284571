import axios, { AxiosRequestConfig } from "axios"

import { CancellableRequest } from "./types"
import { FilledBearerToken } from "../../state/global"

type QrLoginGetStateRequest = CancellableRequest & {
  sessionId: string
  bearerToken: FilledBearerToken
}

type LoginState =
  | "IN_PROGRESS"
  | "REJECTED"
  | "ERROR"
  | "BLOCKED"
  | "TOKEN_INVALID"

export type QrLoginGetStateResponse = QrLoginGetStateResponseBody & {
  authToken?: FilledBearerToken
}

export type QrLoginGetStateResponseBody = {
  loginState: LoginState
}

interface ExecQrLoginGetState {
  (data: QrLoginGetStateRequest): Promise<QrLoginGetStateResponse>
}

const execQrLoginGetState: ExecQrLoginGetState = async data => {
  const { sessionId, bearerToken, cancelToken } = data

  const timeoutInSec = 5 * 60

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${bearerToken}` },
    timeout: timeoutInSec * 1000, // after 5 minutes bearerToken will expire and we need to make new call
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  const response = await axios.get<QrLoginGetStateResponse>(
    `/api/lopi/auth/qrLogin/getState?${sessionId}&timeout=${timeoutInSec}`,
    config
  )

  const { loginState } = response.data

  const result: QrLoginGetStateResponse = {
    loginState,
  }

  if (response.headers.authorization) {
    result.authToken = response.headers.authorization.replace(/^Bearer\s+/i, "")
  }

  return result
}

export default execQrLoginGetState
