import React, { FC } from "react"
import { format } from "date-fns"
import { useRecoilValue } from "recoil"
import { aApaSessionId } from "../../state/auxiliary"
import { aBeErrorData } from "../../state/global"

const ErrorToSend: FC = () => {
  const beErrorData = useRecoilValue(aBeErrorData)
  const sessionId = useRecoilValue(aApaSessionId)

  return (
    <>
      <ul className="mt-4 ul text-sm">
        {beErrorData?.error === "SHOW_ERROR" ? (
          <li>{beErrorData?.message}</li>
        ) : null}
        <li>Čas: {format(new Date(), "dd. MM. yyyy HH:mm:ss OOOO")}</li>
        <li>Kód: {sessionId}</li>
      </ul>
      {beErrorData?.error !== "SHOW_ERROR" ? (
        <p className="mt-4 text-sm">
          Děkujeme za&nbsp;pochopení i&nbsp;za&nbsp;spolupráci.
          Postaráme&nbsp;se, aby&nbsp;všechno zase fungovalo tak, jak&nbsp;má.
        </p>
      ) : null}
    </>
  )
}

export default ErrorToSend
