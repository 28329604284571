import { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import axios from "axios"
import { aApaSessionId } from "./state/auxiliary"
import {
  aBearerToken,
  aDontLogRequestError,
  FilledBearerToken,
  sShouldShowOverlay,
} from "./state/global"

import { execErrorLog } from "./requests/error-log"

import ErrorFallback from "./components/error-fallback"
import Overlay from "./components/overlay"
import Consents from "./pages/consents"
import Error from "./pages/error"
import Login from "./pages/login"
import PublicAuthResult from "./pages/public-auth-result"
import isNetworkError from "./utils/is-network-error"

const App: FC = () => {
  const showOverlay = useRecoilValue(sShouldShowOverlay)
  const feSessionId = useRecoilValue(aApaSessionId)
  const bearerToken = useRecoilValue(aBearerToken) as FilledBearerToken
  const [dontLogRequestError, setDontLogRequestError] =
    useRecoilState(aDontLogRequestError)

  const errorHandler = (error: Error, info: Record<string, string>) => {
    const { componentStack } = info
    try {
      if (!bearerToken) return

      if (isNetworkError(error)) return

      if (
        axios.isAxiosError(error) &&
        error.response?.status &&
        error.response?.status >= 400 && // all 4XX and 5XX should be ignored
        dontLogRequestError
      ) {
        return
      }

      const message = axios.isAxiosError(error)
        ? `${error.message} on ${error.config?.method} ${error.config?.url}`
        : error.message

      setDontLogRequestError(true)

      execErrorLog({
        message,
        stackTrace: componentStack,
        feSessionId,
        bearerToken,
        level: "ERROR",
      })
    } catch {
      // cease logging the event if error occured while logging it
    }
  }

  return (
    <>
      {showOverlay && <Overlay />}
      <Router>
        <Switch>
          <Route path="/consents">
            <ErrorBoundary
              onError={errorHandler}
              FallbackComponent={ErrorFallback}
            >
              <Consents />
            </ErrorBoundary>
          </Route>
          <Route path="/err">
            <Error />
          </Route>
          <Route path="/authresult">
            <ErrorBoundary
              onError={errorHandler}
              FallbackComponent={ErrorFallback}
            >
              <PublicAuthResult />
            </ErrorBoundary>
          </Route>
          <Route path="/">
            <ErrorBoundary
              onError={errorHandler}
              FallbackComponent={ErrorFallback}
            >
              <Login />
            </ErrorBoundary>
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
