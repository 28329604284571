import axios from "axios"

import { BearerToken, ServiceProvider } from "../../state/global"
import { RSAPublicKey } from "../../state/auxiliary"
import { LoginStep } from "../../state/login-step"

export type InitResponse = {
  authorizationAttemptResult: LoginStep["authorizationAttemptResult"]
  authorizationStatus: NonNullable<LoginStep["authorizationStatus"]>
  authToken: NonNullable<BearerToken>
  nextAuthorizationStep: LoginStep["nextAuthorizationStep"]
  rsaPublicKey?: RSAPublicKey
  swtOnlineDeviceName?: string
  swtOnlineValidityInSeconds?: number
  swtOfflineFollowUpData: string | null
  ibUrl?: string
}

export type InitRequest = {
  sessionId: string
  bearerToken: NonNullable<BearerToken>
  appId: ServiceProvider
  userName: string
}

interface ExecLoginInit {
  (data: InitRequest): Promise<InitResponse>
}

const execLoginInit: ExecLoginInit = async data => {
  const { sessionId, bearerToken, ...rest } = data

  if (!bearerToken) {
    throw new Error("Bearer token not set")
    // TODO
  }

  // TODO handle 400 errors?
  const response = await axios.post<InitResponse>(
    `/api/apa/v1/authentication/init?${sessionId}`,
    rest,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  )

  const {
    authToken,
    authorizationStatus,
    authorizationAttemptResult,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOnlineValidityInSeconds,
    swtOfflineFollowUpData,
    ibUrl,
  } = response.data

  return {
    authToken,
    authorizationStatus,
    authorizationAttemptResult,
    nextAuthorizationStep,
    rsaPublicKey,
    swtOnlineDeviceName,
    swtOnlineValidityInSeconds,
    swtOfflineFollowUpData,
    ibUrl,
  }
}

export default execLoginInit
