/**
 * Selectors in this file are meant to batch update Recoil state since a series
 * of updates would cause useEffect() handlers to trigger after each change.
 * Therefore we batch-update the state to smoothly transition between phases.
 */
import { DefaultValue, selector } from "recoil"

import {
  aBearerToken,
  aOperationType,
  aPhase,
  aPreloginAppName,
  aPreloginProviderName,
  aQrBearerToken,
  BearerToken,
  PhaseState,
  aSignRequestId,
} from "./global"
import { aIbUrl } from "./auxiliary"
import {
  aLoginPhaseError,
  aLoginStep,
  aRedirectUrl,
  LoginPhaseError,
} from "./login-step"
import {
  aDocumentsToSign,
  aObjectToSign,
  DocumentsToSign,
  ObjectToSign,
  aEnvelopeName,
  aEnvelopeHash,
} from "./idport"

type ProcessPreLoginSelector = {
  apaInitAuthToken: BearerToken
  appName: string
  providerName: string
  redirectUrl: string
  operationType: "AUTHENTICATION" | "DOCUMENT_SIGN" | ""
  documents: DocumentsToSign
  signObject: ObjectToSign
  signRequestId: string
  envelopeName?: string
  envelopeHash?: string
}

type DocumentTitle = {
  uri?: string | unknown
  text?: string | unknown
  pageCount?: number | unknown
}

export const sProcessPreLogin = selector<ProcessPreLoginSelector>({
  key: "sProcessPreLogin",
  get: ({ get }) => ({
    apaInitAuthToken: get(aBearerToken),
    appName: get(aPreloginAppName),
    providerName: get(aPreloginProviderName),
    redirectUrl: get(aRedirectUrl) || "",
    operationType: get(aOperationType),
    documents: get(aDocumentsToSign),
    signObject: get(aObjectToSign),
    signRequestId: get(aSignRequestId),
    envelopeName: get(aEnvelopeName),
    envelopeHash: get(aEnvelopeHash),
  }),
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const {
      apaInitAuthToken,
      appName,
      providerName,
      redirectUrl,
      operationType,
      documents,
      signObject,
      signRequestId,
      envelopeName,
      envelopeHash,
    } = value

    let mergedDocuments: DocumentsToSign = []

    if (documents) {
      mergedDocuments = [...documents]

      mergedDocuments.map(document => {
        const doc = { ...document }
        const documentTitle: DocumentTitle = {}

        if (doc.uri) {
          documentTitle.uri = doc.uri
        }

        if (doc.title) {
          documentTitle.text = doc.title
        }

        if (doc.page_count) {
          documentTitle.pageCount = doc.page_count
        }

        doc.title = documentTitle

        doc.appName = signObject ? appName : null
        doc.providerName = providerName
        doc.signRequestId = signRequestId

        return doc
      })
    }

    let mergedSignObject: ObjectToSign = {}
    if (signObject) {
      mergedSignObject = { ...signObject }

      if (signRequestId) {
        mergedSignObject.signRequestId = signRequestId
      }
    }

    set(aBearerToken, apaInitAuthToken)
    set(aQrBearerToken, apaInitAuthToken)
    set(aRedirectUrl, redirectUrl)
    set(aPreloginAppName, appName)
    set(aPreloginProviderName, providerName)
    set(aPhase, "qrInit")
    set(aOperationType, operationType)
    set(aDocumentsToSign, documents)
    set(aObjectToSign, mergedSignObject)
    set(aSignRequestId, signRequestId)
    set(aEnvelopeName, envelopeName)
    set(aEnvelopeHash, envelopeHash)
  },
})

type FinishAuthenticationSelector = {
  bearerToken: BearerToken
  phase: PhaseState
  redirectUrl: string | undefined
}

export const sFinishAuthentication = selector<FinishAuthenticationSelector>({
  key: "sFinishAuthentication",
  get: ({ get }) => ({
    bearerToken: get(aBearerToken),
    phase: get(aPhase),
    redirectUrl: get(aRedirectUrl),
  }),
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const { bearerToken, phase, redirectUrl } = value
    set(aBearerToken, bearerToken)
    set(aPhase, phase)
    set(aRedirectUrl, redirectUrl)
    reset(aLoginStep)
  },
})

type HandleLoginErrorSelector = {
  loginPhaseError: LoginPhaseError
  ibUrl?: string
}

export const sHandleLoginError = selector<HandleLoginErrorSelector>({
  key: "sHandleLoginError",
  get: ({ get }) => ({
    loginPhaseError: get(aLoginPhaseError),
    ibUrl: get(aIbUrl),
  }),
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const { loginPhaseError, ibUrl } = value

    set(aLoginPhaseError, loginPhaseError)
    set(aPhase, "error")
    reset(aLoginStep)

    if (ibUrl) {
      set(aIbUrl, ibUrl)
    }
  },
})
