import React, { FC } from "react"

const GlobalErrorForbidden: FC = () => (
  <>
    <h2 className="font-bold text-xl mt-4 sm:mt-0">Neúspěšné přihlášení</h2>
    <hr className="mt-4 mb-6 border-green-brand" />
    <p className="mt-4 text-sm">
      Zadané přihlašovací údaje nejsou správné. Zkuste&nbsp;se přihlásit znovu.
    </p>
  </>
)

export default GlobalErrorForbidden
